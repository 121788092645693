import { request, requestTalentSpy } from "../requestService";
import {
  API_BASE_TALENTSPY_PERKESO_URL,
  API_BASE_TALENTSPY_URL,
  API_BASE_URL_LANDING,
  API_BASE_URL_SKILLSTREETS,
  API_BASE_URL_SKILLSTREETS_PUBLIC,
  BASE_DOMAIN_1,
  API_BASE_TALENTSPY_URL_MAB
} from "../../constants/urlConstants";

// const API_BASE_URL = "https://development."+BASE_DOMAIN_2+"/api/v1/"
const LIST_JOB = "/jobseeker/jobs/getAllJobsProjectListByLocation";
const SEARCH_JOB = "/jobseeker/jobs/getAllJobsProjectListByLocation";
const APPLY_JOB = "/jobseeker/jobs/insertUpdateJobApplication";
const GET_JOB_BOOKMARK_APPLIED =
  "/jobseeker/jobs/getAllBookMarkedOrAppliedJobs";
const INSERT_BOOKMARK = "/jobseeker/jobs/bookMarkJob";

const GET_POSTED_JOBS = "/organization/jobProjectPost/getJobOrProject";
const POST_JOB = "/organization/jobProjectPost/insertUpdatePostJobOrProject";
const GET_APPLIED_JOB_USERS =
  "/organization/jobProjectPost/getAppliedJobSeekerDeatils";
const GET_ORGANIZATION_DETAILS = "/organization/users/getOrganizationDetails";
const SEND_EMAIL_TO_JOB_SEEKER =
  "/organization/jobProjectPost/sendEmailToSpecificJobSeeker";

const SUGGEST_COUNTRY = "/public/suggestCountry";
const SUGGEST_INDUSTRY = "/public/suggestIndustry";
const SUGGEST_JOB_LEVEL = "/public/suggestJobLevel"
const GET_JOB_LEVEL = "/public/getJobLevel"

const GET_ALL_SKILLS = "/skill/getAll";
const GET_ALL_SMART_SKILL = "/smartSkills/getAll";
const GET_CURRENT_USER_INFO = "/userDetails/getCurrentUserInfo";
const GET_ALL_COUNTRY = "/country/getAll";

const GET_CURRENT_JOB_SEEKER_PROFILE = "/jobseeker/getCurrentJobSeekerProfile";
const GET_USER_SKILLS = "/jobseeker/skills/getByJobSeekerId";

const REGISTER_JOBSEEKER = "/public/registerJobSeeker";
const REGISTER_ORGANIZATION = "/public/registerOrganization";
const REGISTER_ORGANIZATION_KC = "/registerOrgAsB2C.json";

const GET_TOKEN = "/thinkific/token";
const REGISTER_INTEREST = "/inquiry/add";


const SET_DATASTAR = "/userDetails/dataStar";

export async function getJobsList(page, size, requestString) {
  return request({
    url:
      API_BASE_URL_SKILLSTREETS +
      LIST_JOB +
      "?pageNo=" +
      page +
      "&pageSize=" +
      size +
      requestString,
    method: "GET",
  });
}
export async function searchJobsList(page, size, searchString) {
  return request({
    url:
      API_BASE_URL_SKILLSTREETS +
      SEARCH_JOB +
      "?pageNo=" +
      page +
      "&pageSize=" +
      size +
      "&designation=" +
      searchString,
    method: "GET",
  });
}
export async function applyForJob(id) {
  return request({
    url: API_BASE_URL_SKILLSTREETS + APPLY_JOB + "/" + id,
    method: "POST",
  });
}

export async function getJobs() {
  return request({
    url: API_BASE_URL_SKILLSTREETS + GET_POSTED_JOBS,
    method: "GET",
  });
}
export async function publishJob(data) {
  return await request({
    url: API_BASE_URL_SKILLSTREETS + POST_JOB,
    method: "POST",
    body: data,
  });
}

export async function getAppliedUsers(id) {
  return request({
    url: API_BASE_URL_SKILLSTREETS + GET_APPLIED_JOB_USERS + "/" + id,
    method: "GET",
  });
}

export async function getOrganizationDetails() {
  return await request({
    url: API_BASE_URL_SKILLSTREETS + GET_ORGANIZATION_DETAILS,
    method: "GET",
  });
}
export async function sendEmailToJobSeeker(data) {
  return await request({
    url: API_BASE_URL_SKILLSTREETS + SEND_EMAIL_TO_JOB_SEEKER,
    method: "POST",
    body: JSON.stringify(data),
  });
}
export async function searchCountry(query) {
  return await request({
    url: API_BASE_URL_SKILLSTREETS_PUBLIC + SUGGEST_COUNTRY + "/" + query,
    method: "GET",
  });
}

export async function searchRole(query) {
  return await request({
    url: API_BASE_URL_SKILLSTREETS_PUBLIC + SUGGEST_JOB_LEVEL + "/" + query,
    method: "GET",
  });
}

export async function getAllRole() {
  return await request({
    url: API_BASE_URL_SKILLSTREETS_PUBLIC + GET_JOB_LEVEL,
    method: "GET",
  });
}

export async function registerJobSeeker(fname, lname, email) {
  return await request({
    url: API_BASE_URL_SKILLSTREETS_PUBLIC + REGISTER_JOBSEEKER,
    method: "POST",
    body: JSON.stringify({
      firstName: fname,
      lastName: lname,
      userId: email,
      userType: 0,
    }),
  });
}
export async function registerJobSeekerTS(fname, lname, email, datastar) {
  return await requestTalentSpy({
    url: API_BASE_TALENTSPY_URL + "/registerEmployeeOnlyTS.json",
    method: "POST",
    body: JSON.stringify({
      firstName: fname,
      lastName: lname,
      userId: email,
      userType: 0,
      datastar: datastar
    }),
  });
}
export async function registerJobSeekerTSMAB(fname, lname, email, datastar) {
  return await requestTalentSpy({
    url: API_BASE_TALENTSPY_URL_MAB + "/registerEmployeeOnlyTS.json",
    method: "POST",
    body: JSON.stringify({
      firstName: fname,
      lastName: lname,
      userId: email,
      userType: 0,
      datastar: datastar
    }),
  });
}
export async function registerJobSeekerTSPERKESO(
  fname,
  lname,
  email,
  subDomain
) {
  return await requestTalentSpy({
    url: API_BASE_TALENTSPY_PERKESO_URL + "/registerEmployeeOnlyTS.json",
    method: "POST",
    body: JSON.stringify({
      firstName: fname,
      lastName: lname,
      userId: email,
      userType: 0,
      isCourseraUser: true,
      subDomain: subDomain,
    }),
  });
}

export async function registerOrganization(
  fname,
  lname,
  email,
  orgType,
  industryId
) {
  return await request({
    url: API_BASE_URL_SKILLSTREETS_PUBLIC + REGISTER_ORGANIZATION,
    method: "POST",
    body: JSON.stringify({
      firstName: fname,
      lastName: lname,
      organizationName: fname,
      userId: email,
      userType: 1,
      organizationTypeId: orgType,
    }),
  });
}

export async function registerOrganizationKC(
  orgName,
  email,
  password,
  industryId
) {
  return await requestTalentSpy({
    url: API_BASE_TALENTSPY_URL + REGISTER_ORGANIZATION_KC,
    method: "POST",
    body: JSON.stringify({
      firstName: orgName,
      lastName: "Organisation",
      emailAddress: email,
      userName: email,
      userType: 1,
      password: password,
      organizationName: orgName,
      industryId: industryId,
    }),
  });
}

export async function searchIndustry(query) {
  return await request({
    url: API_BASE_URL_SKILLSTREETS_PUBLIC + SUGGEST_INDUSTRY + "/" + query,
    method: "GET",
  });
}

export async function getAllJobBookmarkAndApplied() {
  return await request({
    url: API_BASE_URL_SKILLSTREETS + GET_JOB_BOOKMARK_APPLIED,
    method: "GET",
  });
}
export async function insertJobBookmark(id, isBookMarked) {
  return await request({
    url: API_BASE_URL_SKILLSTREETS + INSERT_BOOKMARK,
    method: "POST",
    body: JSON.stringify({
      jobProjectPostedId: id,
      isBookMarked: isBookMarked,
    }),
  });
}

export async function getAllSkills(pageNo, pageSize, sortBy) {
  return await request({
    url:
      API_BASE_URL_SKILLSTREETS +
      GET_ALL_SKILLS +
      "?pageNo=" +
      pageNo +
      "&pageSize=" +
      pageSize +
      "&sortBy=" +
      sortBy,
    method: "GET",
  });
}

export async function getAllSmartSkills(pageNo, pageSize, sortBy) {
  return await request({
    url:
      API_BASE_URL_SKILLSTREETS +
      GET_ALL_SMART_SKILL +
      "?pageNo=" +
      pageNo +
      "&pageSize=" +
      pageSize +
      "&sortBy=" +
      sortBy,
    method: "GET",
  });
}
export async function getJobSeekerById() {
  return await request({
    url: API_BASE_URL_SKILLSTREETS + GET_CURRENT_JOB_SEEKER_PROFILE,
    method: "GET",
  });
}

export async function getUserSkills() {
  return await request({
    url: API_BASE_URL_SKILLSTREETS + GET_USER_SKILLS,
    method: "GET",
  });
}

export async function getCurrentUserInfo() {
  console.log("getCurrentUserInfo called");
  return request({
    url: API_BASE_URL_SKILLSTREETS + GET_CURRENT_USER_INFO,
    method: "GET",
  });
}

export async function getCountryList(pageNo, pageSize, sortBy) {
  return await request({
    url:
      API_BASE_URL_SKILLSTREETS +
      GET_ALL_COUNTRY +
      "?pageNo=" +
      pageNo +
      "&pageSize=" +
      pageSize +
      "&sortBy=" +
      sortBy,
    method: "GET",
  });
}

// WooCommerce order validation api

export async function getOrderDetails(emailId) {
  return request({
    url:
      "https://store." +
      BASE_DOMAIN_1 +
      "/wp-json/wc/v3/orders/?search=" +
      emailId +
      "&consumer_key=ck_2800b3b8837e007fa2abd1ecdc381762ae10427f&consumer_secret=cs_4fcf872606592c5b29820d0ba103123edf260e9d",
    method: "GET",
  });
}

// Talent Spy

export async function getTalentSkills(emailId, skills) {
  return requestTalentSpy({
    url:
      "https://smartskills.talentspy." +
      BASE_DOMAIN_1 +
      "/api/Webservice/getSkills.json",
    method: "POST",
    body: JSON.stringify({
      email: emailId,
      skills: skills,
    }),
  });
}

// register interest data of modal

export async function insertRegisterInterest(
  orgName,
  fname,
  lname,
  email,
  contactNo
) {
  return requestTalentSpy({
    url: API_BASE_URL_LANDING + REGISTER_INTEREST,
    method: "POST",
    body: JSON.stringify({
      Organization: orgName,
      FirstName: fname,
      LastName: lname,
      Email: email,
      ContactNo: contactNo,
    }),
  });
}

// THINIKIFIC

export async function getThinkificToken(firstName, lastName, emailId) {
  return requestTalentSpy({
    url: API_BASE_URL_LANDING + GET_TOKEN,
    method: "POST",
    body: JSON.stringify({
      FirstName: firstName,
      LastName: lastName,
      Email: emailId,
    }),
  });
}

export function courseProgress() {
  return request({
    url:
      "https://www.coursera.org/api/guidedCourseSessionProgresses.v1?ids=80311245~front-end-react&fields=courseId,courseProgressState,currentWeekByUserProgress,endedAt,hasSessionModuleDeadlines,id,startedAt,weeks,onDemandCoursePresentGrades.v1(grade,passingStateForecast,relevantItems),learnerCourseSchedules.v1(id,scheduleTypeContent)",
    method: "GET",
  });
}

export function setDatastar() {
  return request({
    url: API_BASE_URL_SKILLSTREETS + SET_DATASTAR,
    method: 'GET'
  })
}