import { Radar} from 'react-chartjs-2';
import React, {Fragment} from "react";


export default class RadarChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            charData:""
        }
    }

    componentDidMount() {

        let options = {
            legend: {
                display:false,
                position: 'bottom',
            },
            // maintainAspectRatio:true,
            // aspectRatio:2,
            responsive: true,
            scale: {
                angleLines: {
                    display: false
                },
                ticks: {
                    display: false
                    // suggestedMin: 50,
                    // suggestedMax: 100
                }
            }
        };

        let data = {
            labels: ['one', 'two', 'three', 'four', 'five', 'six', 'seven','eight'],
            datasets: [
                {
                    label: 'dataset 1',
                    backgroundColor: 'rgba(78,97,116,0.64)',
                    borderColor: 'rgba(78,97,116,1)',
                    // pointBackgroundColor: 'rgba(179,181,198,1)',
                    pointBackgroundColor: 'rgba(78,97,116,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: 'rgba(78,97,116,1)',
                    pointHoverBorderColor: 'rgba(179,181,198,1)',
                    data: [10, 4, 6, 7, 10, 7, 6, 6]
                },
                {
                    label: 'dataset 2',
                    backgroundColor: 'rgba(53,63,75,0.2)',
                    borderColor: 'rgba(53,63,75,0.2)',
                    pointBackgroundColor: 'rgba(53,63,75,0.2)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: 'rgba(78,97,116,1)',
                    pointHoverBorderColor: 'rgba(179,181,198,1)',
                    data: [14, 8, 8, 9, 10, 9, 8, 8]
                }
            ]
        };
        this.setState({
            chartData:data,
            options:options
        })
    }


    render() {
        let {chartData, options} = this.state;
        return(
            <Fragment>
                {chartData &&
                <div style={{height: "auto"}}>
                    <Radar data={chartData} options={options}/>
                </div>
                }
            </Fragment>
        )
    };
}
