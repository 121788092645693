import axios from 'axios';
import Cookies from 'universal-cookie';

const cookie = new Cookies();


// Updated Code
// function getCookies() {
//     // return cookie.get('access_token');
//     return localStorage.getItem('access_token')
// }

export const request = options => {
    const config = {
        headers: {'Content-Type': 'application/json'},
        url: options['url'],
        method: options['method'],
        data: options['body'] || options['data'],
        // withCredentials: true
    };
    if (cookie.get('access_token', {httpOnly: false})) {

        // Earlier Code
        config['headers']['Authorization'] = 'Bearer ' + cookie.get('access_token');

        // Updated Code
        // config['headers']['Authorization'] = 'Bearer ' + getCookies();
    }
    if(navigator.onLine) {
        return axios(config)
            .then(response => {
                return response.data;
            })
            .catch((error) => {
                if(error && error.response && error.response.status === 401) {
                    cookie.remove('access_token');
                    cookie.remove('refresh_token');
                    setTimeout(() => {
                        // window.location.href = '/'
                    }, 300)
                }
                return error;
            })
    } else {
        let response;
        response = {
            status: false,
            tempMassage: "INTERNET DISCONNECTED"
        };
        return response;
    }
};


export const requestTalentSpy = options => {
    const config = {
        headers: {'Content-Type': 'application/json','Upgrade-Insecure-Requests': '1'},
        url: options['url'],
        method: options['method'],
        data: options['body'] || options['data'],
        // withCredentials: true
    };
    if (cookie.get('access_token', {httpOnly: false})) {

        // Earlier Code
        config['headers']['Authorization'] = 'Bearer ' + cookie.get('access_token', {httpOnly: false, path: '/'});

        // Updated Code
        // config['headers']['Authorization'] = 'Bearer ' + getCookies();
    }
    if(navigator.onLine) {
        return axios(config)
            .then(response => {
                return response.data;
            })
            .catch((error) => {
                if(error && error.response && error.response.status === 401) {
                    cookie.remove('access_token');
                    cookie.remove('refresh_token');
                    setTimeout(() => {
                        // window.location.href = '/'
                    }, 300)
                }
                return error;
            })
    } else {
        let response;
        response = {
            status: false,
            tempMassage: "INTERNET DISCONNECTED"
        };
        return response;
    }
};
