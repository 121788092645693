import React, {Fragment} from 'react';
import {Button, Col, Container, Row} from "reactstrap";
import { BASE_DOMAIN_2 } from '../../../constants/urlConstants';

export default class TempPage extends React.Component{

    constructor(props) {
        super(props);
    }

    render() {

        return(
            <Fragment>
                <div className={"container-fluid"}>
                    <Row style={{border: "2px solid black"}}>
                        <Col  className={"pt-4 pb-4 pl-4"} xl={"12"}>
                            <div style={{float:"left"}} className={"logoClass"} >
                                <img src={"/assets/img/CadsBig.png"} height={"inherit"} width={"160px"}/>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={3}>

                            <div className={"mt-4 ml-3"}>
                                <a href={"https://ddo.thecads.com/welcome"}>
                                    <div className={"leftDiv"}>
                                          <h1>Data Driven
                                              Organization
                                          </h1>
                                        <h4>
                                            Maturity Assessment
                                        </h4>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col xl={9}>
                            <Row>
                                <Col xl={"12"}>
                                    <div className={"arrowImage"}>
                                        <a href={"#"}>
                                            <img src={"/assets/img/arrowUp.png"} height={"150px"} width={"100%"}/>
                                        </a>
                                    </div>
                                </Col>
                                <Col className={"p-2"} xl={12}>
                                    <div style={{marginLeft:"10%"}}>
                                        <a href={"http://development."+BASE_DOMAIN_2+"/jobSeeker"}>
                                            <div className={"arrow arrow1"}>
                                            </div>
                                        </a>
                                        <a href={"http://development."+BASE_DOMAIN_2+"/jobSeeker"}>
                                            <div className={"arrow arrow2"}>
                                            </div>
                                        </a>
                                        <a href={"#"}>
                                            <div className={"arrow arrow3"}>
                                            </div>
                                        </a>
                                        <a href={"#"}>
                                            <div className={"arrow arrow4"}>
                                            </div>
                                        </a>
                                        <a href={"#"}>
                                            <div className={"arrow arrow5"}>
                                            </div>
                                        </a>
                                        <a href={"#"}>
                                            <div className={"arrow arrow6"}>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                                <Col xl={"12"}>
                                    <div className={"arrowImage"}>
                                        <a href={"#"}>
                                            <img src={"/assets/img/arrowDown.png"} height={"150px"} width={"100%"}/>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </div>
            </Fragment>
        )
    }

}
