let API_BASE_LANDING = process.env.REACT_APP_API_BASE_LANDING;
let API_BASE_SKILLSTREETS = process.env.REACT_APP_API_BASE_SKILLSTREETS;
let API_BASE_TALENTSPY = process.env.REACT_APP_API_BASE_TALENTSPY;
let API_BASE_TALENTSPY_MAB = process.env.REACT_APP_API_BASE_TALENTSPY_MAB;
let API_BASE_TALENTSPY_PERKESO = process.env.REACT_APP_API_BASE_TALENTSPY_PERKESO;
let URL_BASE_LOGIN = process.env.REACT_APP_URL_BASE_LOGIN;
let ENV_BASE_COOKIE = process.env.REACT_APP_ENV_BASE_COOKIE;
let ENV_REDIRECTION_URL = process.env.REACT_APP_ENV_REDIRECTION_URL;
let ENV_SKILLSTREET_REDIRECTION = process.env.REACT_APP_ENV_SKILLSTREET_REDIRECTION;
let ENV_KEYCLOAK_URL = process.env.REACT_APP_ENV_KEYCLOAK_URL;
let ENV_BASE_DOMAIN_1 = process.env.REACT_APP_BASE_DOMAIN_1;
let ENV_BASE_DOMAIN_2 = process.env.REACT_APP_BASE_DOMAIN_2;
let APHRODITE_ROOT_UI = process.env.REACT_APP_URL_BASE_LOGIN;

export const API_BASE_URL_LANDING = API_BASE_LANDING + '/api';
export const APHRODITE_UI = APHRODITE_ROOT_UI;
export const API_BASE_URL_SKILLSTREETS = API_BASE_SKILLSTREETS + '/api/v1';
export const API_BASE_URL_SKILLSTREETS_PUBLIC = API_BASE_SKILLSTREETS;
export const BASE_COOKIE = ENV_BASE_COOKIE;
export const TALENT_SPY_ASSESSMENT_LINK = 'http://smartskills.talentspy.dev.skillstreets.com'
export const REDIRECTION_URL = ENV_REDIRECTION_URL;
export const SKILLSTREET_REDIRECTION = ENV_SKILLSTREET_REDIRECTION
export const URL_LOGIN = URL_BASE_LOGIN;
export const API_BASE_TALENTSPY_URL = API_BASE_TALENTSPY;
export const API_BASE_TALENTSPY_URL_MAB = API_BASE_TALENTSPY_MAB;
export const API_BASE_TALENTSPY_PERKESO_URL = API_BASE_TALENTSPY_PERKESO;
export const KEYCLOAK_URL = ENV_KEYCLOAK_URL;
export const BASE_DOMAIN_1 = ENV_BASE_DOMAIN_1
export const BASE_DOMAIN_2 = ENV_BASE_DOMAIN_2

let url = window && window.location && window.location.href;

let isProd = false;

if(url.includes("dev")) {

} else if (url.includes("stag")) {

}  else if (url.includes("localhost")) {

} else if (url.includes("cads.ai")) {
    isProd = true
}

export const IS_PROD = isProd;
