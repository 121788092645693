import React, { Component, Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import RegFields from './RegFields';
import {INIT_OPTIONS} from "../../constants/global";
import * as Keycloak from "keycloak-js";
import './style.css';
import {REDIRECTION_URL} from "../../constants/urlConstants";
export default class RegistrationPage extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		let keycloak = Keycloak(INIT_OPTIONS);
		keycloak.init();
		keycloak.register({redirectUri:REDIRECTION_URL+'/?rubiqe'}).then(action => {

		}).catch(error => {
		})
	}

	render() {
		return (
			<Fragment>
				{/*<div*/}
				{/*	className='container-fluid pl-0'*/}
				{/*	style={{ width: '100%', backgroundColor: 'white' }}*/}
				{/*>*/}
				{/*	<Row>*/}
				{/*		<Col md={'6'}>*/}
				{/*			<div*/}
				{/*				className={*/}
				{/*					'logo-div d-flex align-items-center justify-content-center'*/}
				{/*				}*/}
				{/*				style={{ height: '100vh' }}*/}
				{/*			>*/}
				{/*				<img*/}
				{/*					src={require('./Powered by CADS@2x.png')}*/}
				{/*					height={'inherit'}*/}
				{/*					width={'220x'}*/}
				{/*					alt='CADS Logo'*/}
				{/*				/>*/}
				{/*			</div>*/}
				{/*		</Col>*/}
				{/*		<Col md={'6'}>*/}
				{/*			<RegFields {...this.props} />*/}
				{/*		</Col>*/}
				{/*	</Row>*/}
				{/*</div>*/}
			</Fragment>
		);
	}
}
