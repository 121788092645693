import {Slide, toast} from 'react-toastify';

export default function showToast(msg) {
  return toast.success(msg,{
    transition: Slide,
    closeButton: true,
    autoClose: 3000,
    position: 'top-right',
});
}

export function showToastLong(msg) {
  return toast.success(msg,{
    transition: Slide,
    closeButton: true,
    autoClose: 6000,
    position: 'top-right',
});
}


export function warningToast(msg) {
  return toast.error(msg,{
    transition: Slide,
    closeButton: true,
    autoClose: 3000,
    position: 'top-right',
  });
}
