import React, {Fragment } from 'react';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import { Button } from 'reactstrap';
import {IS_PROD, REDIRECTION_URL, URL_LOGIN} from "../../constants/urlConstants"
import {INIT_OPTIONS} from "../../constants/global";
import * as Keycloak from "keycloak-js";
import {Link} from "react-router-dom";
import { Col, Row } from "reactstrap";
import Container from 'reactstrap/lib/Container';


class NavBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = ({
            isOpen:false
        })
    }
    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    registerUser = () => {
        window.location.href="/signup";
            // let keycloak = Keycloak(INIT_OPTIONS);
            // keycloak.init();
            // keycloak.register({redirectUri:REDIRECTION_URL}).then(action => {
            //
            // }).catch(error => {
            // })
    }

    render() {

        return (
            <Fragment>
                <Container fluid>
                    <Row className="navbar-padding">
                        <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                            <div className={"logoClass"} >
                                <img src={"https://general-assets.sgp1.digitaloceanspaces.com/rubiqe-acceltic-logos/cads-primary.png"} className={"imageClass"}/>
                            </div>
                        </Col>
                        <Col xl={9} lg={9} md={9} sm={9} xs={9} style={{alignSelf: "center"}}>
                            <div style={{float:"right"}}>
                                <Link className={"footerLink mr-2"} to={"/app#login-b2c"}>
                                    <Button outline className="navLoginBtn" color="primary">Log in</Button>
                                </Link>
                                <Button onClick={this.registerUser} className="navRegisterBtn"  color="primary">Sign up</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
};

export default NavBar;

