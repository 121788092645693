import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import { toast, ToastContainer } from "react-toastify";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Input from "reactstrap/es/Input";
import styled from "styled-components";
import { getAnnouncement } from "../../service/landingService";
import { insertRegisterInterest } from "../../service/userService";
import ImageCarouselComponent from "../../components/ImageCarousel/index";

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const AutoplaySlider = withAutoplay(AwesomeSlider);

const HomeTitle = styled.h1`
	font-weight: 700;
	text-align: left;
	color: grey;
	font-size: 22px;
	line-height: 1.55;
	margin-bottom: 23px;

	@media (min-width: 992px) {
		font-size: 75px;
		line-height: 1.39;
		max-width: 1100px;
		margin-left: 0px;
		margin-right: auto;
		margin-bottom: 0;
	}
`;

const SubTitle = styled.h5`
	font-size: 17px;
	font-weight: normal;
	line-height: 1.83;
	text-align: left;
	color: grey;

	@media (min-width: 992px) {
		max-width: auto;
		margin-left: 0px;
		margin-right: 0px;
		margin-bottom: 0px;
	}
	@media screen and (min-width: 320px) and (max-width: 480px) {
		font-size: 14px;
		font-weight: 500;
		line-height: 1.53;
	}
`;

export default class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			title: "",
			subtitle: "",
			orgName: null,
			fName: null,
			lname: null,
			email: null,
			contactNo: null,
		};
	}
	componentDidMount() {
		this.getAnnouncementData();
	}

	toggle = () => {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	};

	getAnnouncementData = () => {
		getAnnouncement().then((response) => {
			if (response && response.data) {
				this.setState({
					title: response.data && response.data[0] && response.data[0].title,
					subtitle: response.data && response.data[0] && response.data[0].subTitle,
				});
			} else {
				this.setState({
					title: null,
					subtitle: null,
				});
			}
		});
	};

	hideAnnouncement = () => {
		let announcementId = document.getElementById("announcement");
		announcementId.style.display = "none";
	};

	registerData = () => {
		if (
			this.state.orgName &&
			this.state.orgName.trim().length > 0 &&
			this.state.fName &&
			this.state.fName.trim().length > 0 &&
			this.state.lName &&
			this.state.lName.trim().length > 0 &&
			this.state.email &&
			this.state.email.trim().length > 0 &&
			this.state.contactNo &&
			this.state.contactNo.trim().length > 0
		) {
			if (emailRegex.test(this.state.email.trim())) {
				if (
					isNaN(this.state.contactNo) ||
					this.state.contactNo.length < 1 ||
					this.state.contactNo.length > 10
				) {
					toast("Please enter valid number");
				} else {
					insertRegisterInterest(
						this.state.orgName,
						this.state.fName,
						this.state.lName,
						this.state.email,
						this.state.contactNo,
					).then((response) => {
						if (response && response.returnStatus) {
							this.toggle();
							toast("Your Interest Registered");
						} else {
							toast(response.message);
						}
					});
				}
			} else {
				toast("Please enter valid email");
			}
		} else {
			toast("Please enter all fields");
		}
	};

	handleOnChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	render() {
		return (
			<Fragment>
				<div className="container-fluid">
					{this.state.title && (
						<Row className={"announcement"} id={"announcement"}>
							<Col md={11} sm={10} xs={10}>
								<div>
									<h5 className={"mt-2 mb-0"} style={{fontWeight: '700'}}>
										{this.state.title}
									</h5>
								</div>
								<div>
									<div className="mb-2" style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: this.state.subtitle }} />
								</div>
							</Col>
							<Col className={"cancelButton"} md={1} sm={2} xs={2}>
								<FontAwesomeIcon
									onClick={this.hideAnnouncement}
									icon={faTimesCircle}
								/>
							</Col>
						</Row>
					)}
				</div>
				<div
					className="container"
					style={{ maxWidth: "1100px", color: "#3a414b" }}
				>
					<Row className={"infoRow"}>
						<Col md={"12"}>
							<h1
								className="homeTitle"
							>
								Future proof the world.
							</h1>
							<SubTitle
								data-aos="fade-up"
								data-aos-easing="ease"
								data-aos-delay="400"
								style={{ color: "#3A414B" }}
							>
								Industry 4.0 is about to change the traditional business model.
								Hence, it is no longer an option for businesses to run based on
								the people’s current skills without preparing them to be ready
								for the inevitable digital change.
							</SubTitle>
						</Col>
					</Row>
					<Row className={"mt-4 circle-data"}>
						<Col xl={"12"}>
							<Row>
								<Col xl={"4"} md={"4"} className={"mt-3"}>
									<div className={"text-center"}>
										<div className={"circleImages"}>
											<img
												src={"/assets/img/Badge_icon@3x.png"}
												alt=""
												width={"75px"}
												height={"75px"}
											/>
										</div>
										<div>
											<p className={"circleHeading"}>
												<b>3000</b>
											</p>
											<p className={"circleData"}>
												People upskilled in data, analytics and AI skills.
											</p>
										</div>
									</div>
								</Col>
								<Col xl={"4"} md={"4"} className={"mt-3"}>
									<div className={"text-center"}>
										<div className={"circleImages"}>
											<img
												src={"/assets/img/Building_Icon@3x.png"}
												alt=""
												width={"75px"}
												height={"75px"}
											/>
										</div>
										<div>
											<p className={"circleHeading"}>
												<b>50</b>
											</p>
											<p className={"circleData"}>
												Organizations guided to become data-driven.
											</p>
										</div>
									</div>
								</Col>
								<Col xl={"4"} md={"4"} className={"mt-3 pr-0"}>
									<div className={"text-center"}>
										<div className={"circleImages"}>
											<img
												src={"/assets/img/People_Icon@3x.png"}
												alt=""
												width={"75px"}
												height={"75px"}
											/>
										</div>
										<div>
											<p className={"circleHeading"}>
												<b>400</b>
											</p>
											<p className={"circleData"}>
												People placed in organizations as data professionals.
											</p>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
				<div className="container-fluid">
					<ImageCarouselComponent />
				</div>
				<Modal isOpen={this.state.isOpen} size={"md"}>
					<ModalHeader>Register Your Interest</ModalHeader>
					<ModalBody>
						<div className={"p-2"}>
							<Row>
								<Col md={"12"}>
									<p className={"font-18 font-w-600"}>Organization</p>
									<Input
										name={"orgName"}
										onChange={this.handleOnChange}
										type={"text"}
									/>
									<p className={"mt-4 font-18 font-w-600"}>First Name</p>
									<Input
										name={"fName"}
										onChange={this.handleOnChange}
										type={"text"}
									/>
									<p className={"mt-4 font-18 font-w-600"}>Last Name</p>
									<Input
										name={"lName"}
										onChange={this.handleOnChange}
										type={"text"}
									/>
									<p className={"mt-4 font-18 font-w-600"}>Email</p>
									<Input
										name={"email"}
										onChange={this.handleOnChange}
										type={"text"}
									/>
									<p className={"mt-4 font-18 font-w-600"}>Contact No</p>
									<Input
										name={"contactNo"}
										onChange={this.handleOnChange}
										type={"text"}
									/>
								</Col>
							</Row>
							<Row className={"mt-4"}>
								<Col className={"text-center"} md={"12"}>
									<p className={"font-18 font-w-600"}>
										Our customer engagement specialist will be in touch with you
										soon. Thank you
									</p>
								</Col>
							</Row>
							<Row>
								<Col md={"12"} className={"text-right mt-4"}>
									<Button
										className={"btn-round draft-button mr-4"}
										onClick={this.toggle}
									>
										Cancel
									</Button>
									<Button className={"btn-round "} onClick={this.registerData}>
										Submit
									</Button>
								</Col>
							</Row>
						</div>
					</ModalBody>
				</Modal>
				<ToastContainer />
				{/* <AutoplaySlider></AutoplaySlider> */}

				
			</Fragment>
		);
	}
}
