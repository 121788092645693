import React, { Fragment } from "react";
import BarChart from "../../charts/BarChart";
import BarChartData from "../../data/BarChartData";
import clsx from "clsx";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Loader from "react-loader-spinner";
import Select from "react-dropdown-select";
import { Input, Row, Col, Button } from "reactstrap";
// import "./QuickFacts.css";
import World from "@svg-maps/world";
import { SVGMap } from "react-svg-map";
import ReactCountryFlag from "react-country-flag";
import { getCountryData, getWorldData } from "../../service/landingService";
import { toast } from "react-toastify";
import JobSalaries from "../../views/JobSalaries";
import { Select as Select2, MenuItem } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import "./custom.scss";
class QuickFacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTopic: "salary-benchmark",
      tabView: "Map",
      pointedLocation: null,
      countryCode: "",
      tooltipStyle: {
        display: "none",
      },
      tableData: "",
      totalData: "",
      mapData: "",
      confirmed: "",
      recovered: "",
      deaths: "",
      blocking: false,
      dropDownList: [],
    };
  }

  componentDidMount() {
    this.getAllWorldData();
  }

  getAllWorldData = () => {
    this.setState({
      blocking: true,
    });
    getWorldData()
      .then((response) => {
        this.setState({
          blocking: false,
        });
        if (response && response.data !== null) {
          this.setState({
            mapData: response.data.covidCountrylist,
            totalData: response.data.covidTotal,
            dropDownList: response.data.covidCountrylist,
          });
          let countries = response.data.covidCountrylist;
          for (let i in countries) {
            if (countries[i].country_Name === "Malaysia") {
              countries.splice(i, 1);
            }
          }

          this.setState({
            tableData: countries,
          });
        } else {
          this.setState({
            blocking: true,
          });
        }
      })
      .catch((error) => {
        toast(" erro ", error);
      });
  };

  getAllCountryData = (countryId) => {
    this.setState({
      blocking: true,
    });
    getCountryData(countryId)
      .then((response) => {
        this.setState({
          blocking: false,
        });
        if (response && response.data !== null) {
          this.setState({
            tableData: response.data.covidStatelist,
            totalData: response.data.covidCountry,
          });
        } else {
          this.setState({
            blocking: true,
          });
        }
      })
      .catch((error) => {});
  };

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  changeTabView = (e) => {
    let tabView = "Map";
    if (this.state.tabView === "Map") {
      tabView = "List";
      this.getAllWorldData();
    }
    this.setState({
      tabView: tabView,
      // tableData: this.state.mapData
    });
  };

  handleOnChange = (event, value) => {
    console.log(event, value);
    let selectedValue = event.target.value;
    if (
      selectedValue === "Coronavirus" ||
      selectedValue === "Global Unemployment" ||
      selectedValue === "salary-benchmark"
    ) {
      if (selectedValue === "Coronavirus") {
        this.getAllWorldData();
      }
      this.setState({
        selectedTopic: selectedValue,
      });
    }
  };

  handleCountryChange = (value) => {
    let selectedValue;
    if (value.length !== 0) {
      selectedValue = value[0].country_Id;
    } else {
      selectedValue = 0;
    }

    if (selectedValue === 0) {
      this.getAllWorldData();
    } else {
      this.getAllCountryData(selectedValue);
    }
  };

  handleLocationMouseOver = (event) => {
    let pointedLocation = event.target.attributes.name.value;
    let countryCode = event.target.id;
    let mapData = this.state.mapData;
    for (let i = 0; i < mapData.length; i++) {
      if (
        pointedLocation.toUpperCase() ===
          mapData[i].country_Name.toUpperCase() ||
        countryCode.toUpperCase() === mapData[i].country_Name.toUpperCase()
      ) {
        this.setState({
          confirmed: mapData[i].confirmed,
          recovered: mapData[i].recovered,
          deaths: mapData[i].deaths,
        });
        break;
      } else {
        this.setState({
          confirmed: "No data found",
          recovered: "No data found",
          deaths: "No data found",
        });
      }
    }
    this.setState({ pointedLocation, countryCode });
  };

  handleLocationMouseOut = () => {
    this.setState({
      pointedLocation: null,
      tooltipStyle: { display: "none" },
    });
  };

  handleLocationMouseMove = (event) => {
    let tooltipStyle = {
      display: "block",
      top: event.clientY + 10,
      left: event.clientX - 100,
    };
    this.setState({ tooltipStyle });
  };

  render() {
    const filteredData = BarChartData;

    return (
      <Fragment>
        <BlockUi
          tag="div"
          blocking={this.state.blocking}
          loader={
            <Loader
              type="Oval"
              color="grey"
              height={50}
              width={50}
              timeout={13000} //3 secs
            />
          }
          message="Loading.."
        >
          <div className="container-fluid" style={{ marginTop: "10vh" }}>
            <h2
              className={"fontWeight700"}
              style={{
                marginLeft: "8vh",
                fontSize: "x-large",
                color: "#4e6074",
              }}
            >
              See what's happening around the world
            </h2>
            <Row>
              <Col
                xl={"3"}
                style={{
                  backgroundColor: "#152234",
                  color: "grey",
                  marginLeft: "10vh",
                  borderRadius: "12px",
                }}
              >
                <div
                  style={{
                    padding: "2.5vh",
                    color: "white",
                    marginTop: "20px",
                  }}
                >
                  <div>
                    <h6 className={"fontWeight700"}>Select Topic</h6>
                    <FormControl
                      className={"w-100"}
                      hiddenLabel={false}
                      // variant="outlined"
                      size={"small"}
                      label={"Company Size"}
                    >
                      {/*<InputLabel id="demo-simple-select-outlined-label">*/}

                      {/*</InputLabel>*/}
                      <Select2
                        className={"quick-facts-select"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={"Coronavirus"}
                        onChange={this.handleOnChange}
                        variant={"outlined"}
                        autoWidth
                        size={"small"}
                        value={this.state.selectedTopic}
                      >
                        <MenuItem value={"salary-benchmark"}>
                          Data Professional Salary Benchmark
                        </MenuItem>
                        <MenuItem value={"Coronavirus"}>Coronavirus</MenuItem>
                      </Select2>
                    </FormControl>
                    {/*<Input*/}
                    {/*	// style={{ color: "white", background: "transparent" }}*/}
                    {/*	type="select"*/}
                    {/*	name="facts"*/}
                    {/*	id="facts"*/}
                    {/*	onChange={this.handleOnChange}*/}
                    {/*>*/}
                    {/*	<option value={"Coronavirus"}>Coronavirus</option>*/}
                    {/*	<option value={"salary-benchmark"}>Data Professional Salary Benchmark</option>*/}
                    {/*	/!*<option value={"Global Unemployment"}>*!/*/}
                    {/*	/!*	Global Unemployment*!/*/}
                    {/*	/!*</option>*!/*/}
                    {/*	/!*<option value={"US-China Trade War"}>*!/*/}
                    {/*	/!*	US-China Trade War*!/*/}
                    {/*	/!*</option>*!/*/}
                    {/*	/!*<option value={"Data Science Jobs"}>*!/*/}
                    {/*	/!*	Data Science Jobs*!/*/}
                    {/*	/!*</option>*!/*/}
                    {/*</Input>*/}
                  </div>
                </div>
                <div style={{ padding: "0vh 2vh 2vh 2vh" }}>
                  <h2 className={"fontWeight700"} style={{ color: "#1284ff" }}>
                    Quick Facts
                  </h2>
                  {this.state.selectedTopic === "Coronavirus" && (
                    <Fragment>
                      <div className={"mt-4"} style={{ color: "white" }}>
                        <h3 className={"fontWeight700"}>
                          {this.state.totalData.confirmed &&
                            this.numberWithCommas(
                              this.state.totalData.confirmed
                            )}
                          <span style={{ fontSize: "medium" }}>
                            (
                            {this.state.totalData.today_Confirmed &&
                              this.numberWithCommas(
                                this.state.totalData.today_Confirmed
                              )}
                            <span style={{ fontSize: "small" }}> today</span>)
                          </span>
                        </h3>
                        <h6 className={"fontWeight400"}>Confirmed Cases</h6>
                      </div>
                      <div className={"mt-4"} style={{ color: "white" }}>
                        <h3 className={"fontWeight700"}>
                          {this.state.totalData.recovered &&
                            this.numberWithCommas(
                              this.state.totalData.recovered
                            )}
                          <span style={{ fontSize: "medium" }}>
                            (
                            {this.state.totalData.today_Recovered &&
                              this.numberWithCommas(
                                this.state.totalData.today_Recovered
                              )}
                            <span style={{ fontSize: "small" }}>today</span>)
                          </span>
                        </h3>
                        <h6 className={"fontWeight400"}>Recovered</h6>
                      </div>
                      <div className={"mt-4"} style={{ color: "white" }}>
                        <h3 className={"fontWeight700"}>
                          {this.state.totalData.deaths &&
                            this.numberWithCommas(this.state.totalData.deaths)}
                          <span style={{ fontSize: "medium" }}>
                            (
                            {this.state.totalData.today_Deaths &&
                              this.numberWithCommas(
                                this.state.totalData.today_Deaths
                              )}
                            <span style={{ fontSize: "small" }}>today</span>)
                          </span>
                        </h3>
                        <h6 className={"fontWeight400"}>Deaths</h6>
                      </div>
                    </Fragment>
                  )}

                  {this.state.selectedTopic === "salary-benchmark" && (
                      <div id={"job-salaries-quick-facts"} style={{color: 'white'}}>

                      </div>
                  )}

                  {this.state.selectedTopic === "Global Unemployment" && (
                    <Fragment>
                      <div className={"mt-4"}>
                        <h3 className={"fontWeight700"}>278,500</h3>
                        <h6 className={"fontWeight400"}>
                          Unemployements Globally
                        </h6>
                      </div>
                      <div className={"mt-4"}>
                        <h3 className={"fontWeight700"}>650,110</h3>
                        <h6 className={"fontWeight400"}>
                          Salary Cuts Globally
                        </h6>
                      </div>
                    </Fragment>
                  )}
                </div>
              </Col>
              <Col
                xl={"8"}
                style={{ backgroundColor: "#FCFCFC", borderRadius: "12px" }}
              >
                {this.state.selectedTopic === "salary-benchmark" && (
                  <JobSalaries />
                )}
                {this.state.selectedTopic === "Coronavirus" ? (
                  <div className="row right-facts-section">
                    <div className="col-md-12">
                      <div className="row right-facts-content">
                        <div className="col-md-6">
                          <h3
                            className={"fontWeight700"}
                            style={{ color: "#3a414b" }}
                          >
                            Coronavirus
                          </h3>
                          <h6
                            className={"fontWeight700"}
                            style={{ color: "#3a414b" }}
                          >
                            Global Coronavirus Cases
                          </h6>
                        </div>
                        <div className="col-md-3 text-center">
                          <div className={"toggle"}>
                            <div
                              className="right-tab-view mb-2"
                              style={{ color: "#3a414b" }}
                            >
                              <Button
                                size="sm"
                                className={clsx("tab-inactive", {
                                  "tab-active": this.state.tabView === "Map",
                                })}
                                onClick={this.changeTabView}
                              >
                                Map
                              </Button>
                              &nbsp;
                              <Button
                                size="sm"
                                className={clsx("tab-inactive", {
                                  "tab-active": this.state.tabView === "List",
                                })}
                                onClick={this.changeTabView}
                              >
                                List
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 text-center">
                          <div>
                            {this.state.tabView === "Map" && (
                              <Input
                                style={{ color: "#3a414b" }}
                                type="select"
                                name="facts"
                                id="facts"
                              >
                                <option value={"Coronavirus"}>Worldwide</option>
                              </Input>
                            )}
                            {this.state.tabView === "List" && (
                              <Select
                                placeholder={"Search"}
                                labelField={"country_Name"}
                                searchBy={"country_Name"}
                                valueField={"country_Id"}
                                options={this.state.dropDownList}
                                closeOnSelect={true}
                                clearable={true}
                                onChange={(values) =>
                                  this.handleCountryChange(values)
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.tabView === "Map" ? (
                      <div className="col-md-12">
                        <div className="row right-facts-content">
                          <div className="col-md-12">
                            <div className="content">
                              <SVGMap
                                map={World}
                                className={"mapColor"}
                                onLocationMouseOver={
                                  this.handleLocationMouseOver
                                }
                                onLocationMouseOut={this.handleLocationMouseOut}
                                onLocationMouseMove={
                                  this.handleLocationMouseMove
                                }
                              />
                              <div
                                className="examples__block__map__tooltip"
                                style={this.state.tooltipStyle}
                              >
                                <ReactCountryFlag
                                  className={"mr-1"}
                                  countryCode={this.state.countryCode}
                                  svg
                                  style={{
                                    width: "3em",
                                    height: "2em",
                                  }}
                                  title={this.state.pointedLocation}
                                />
                                {/*<img className={"mr-1"} src={"/assets/img/countryFlag/"+this.state.pointedLocation+".png"} alt={"flag"} width={"60px"} height={"30px"} />*/}
                                {this.state.pointedLocation}
                                <p className={"mt-0 mb-0"}>
                                  <span className={"fontWeight700"}>
                                    Confirmed
                                  </span>
                                  {this.state.confirmed &&
                                    this.numberWithCommas(this.state.confirmed)}
                                </p>
                                <p className={"mt-0 mb-0"}>
                                  <span className={"fontWeight700"}>
                                    Recovered
                                  </span>
                                  {this.state.recovered &&
                                    this.numberWithCommas(this.state.recovered)}
                                </p>
                                <p className={"mt-0 mb-0"}>
                                  <span className={"fontWeight700"}>
                                    Deaths
                                  </span>
                                  {this.state.deaths &&
                                    this.numberWithCommas(this.state.deaths)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {this.state.tabView === "List" ? (
                      <div className=" list-data col-md-12">
                        <div className="row right-facts-content">
                          <div className="col-md-12">
                            <div className="content">
                              <table className="table tab-table">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Confirmed</th>
                                    <th>Recovered</th>
                                    <th>Deaths</th>
                                  </tr>
                                  <tr className={"first-td"}>
                                    <td>
                                      {this.state.totalData.hasOwnProperty(
                                        "name"
                                      ) && this.state.totalData.name}
                                      {this.state.totalData.hasOwnProperty(
                                        "country_Name"
                                      ) && this.state.totalData.country_Name}
                                    </td>
                                    <td>
                                      {this.state.totalData.confirmed &&
                                        this.numberWithCommas(
                                          this.state.totalData.confirmed
                                        )}
                                    </td>
                                    <td>
                                      {this.state.totalData.recovered &&
                                        this.numberWithCommas(
                                          this.state.totalData.recovered
                                        )}
                                    </td>
                                    <td>
                                      {this.state.totalData.deaths &&
                                        this.numberWithCommas(
                                          this.state.totalData.deaths
                                        )}
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.tableData.map((data, index) => (
                                    <tr key={index}>
                                      <td
                                        className={clsx({
                                          "": index === 0,
                                        })}
                                      >
                                        {data.hasOwnProperty("country_Name") &&
                                          data.country_Name}
                                        {data.hasOwnProperty("state_Name") &&
                                          data.state_Name}
                                      </td>
                                      <td
                                        className={clsx({
                                          "": index === 0,
                                        })}
                                      >
                                        {this.numberWithCommas(data.confirmed)}
                                      </td>
                                      <td
                                        className={clsx({
                                          "": index === 0,
                                        })}
                                      >
                                        {this.numberWithCommas(data.recovered)}
                                      </td>
                                      <td
                                        className={clsx({
                                          "": index === 0,
                                        })}
                                      >
                                        {this.numberWithCommas(data.deaths)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {this.state.selectedTopic === "Global Unemployment" ? (
                  <div className="row right-facts-section">
                    <div className="col-md-12">
                      <div className="row right-facts-content">
                        <div className="col-md-9">
                          <h3 className={"fontWeight700"}>
                            Global Unemployment
                          </h3>
                          <h6 className={"fontWeight700"}>
                            Worldwide Unemployment rate
                          </h6>
                        </div>
                        <div className="col-md-3">
                          <Input
                            style={{ color: "grey" }}
                            type="select"
                            name="facts"
                            id="facts"
                          >
                            <option value={"Coronavirus"}>Worldwide</option>
                          </Input>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row right-facts-content">
                        <div className="col-md-12">
                          <div
                            style={{ overflowX: "scroll", overflowY: "hidden" }}
                          >
                            <BarChart
                              data={filteredData}
                              width={1000}
                              height={550}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
        </BlockUi>
      </Fragment>
    );
  }
}

export default QuickFacts;
