import React, {Fragment} from "react";
import {
    Row,
    Col,
    Button,
    Input,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    Navbar,
    Card,
    CardBody
} from "reactstrap";
import LearningPath from "../../../charts/B2BCharts/LearningPath";
import ModulePath from "../../../charts/B2BCharts/ModulePath";
import PathCompletion from "../../../charts/B2BCharts/PathCompletion";
import AssessmentStatus from "../../../charts/B2BCharts/AssessmentStatus";
import JobMetrices from "../../../charts/B2BCharts/JobMetrices";
import ProjectMetrics from "../../../charts/B2BCharts/ProjectMetrics";
import OrganizationSkill from "../../../charts/B2BCharts/OrganizationSkill";
import {URL_LOGIN} from "../../../constants/urlConstants";

export default class ChartsDashBoard extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    render() {
        return(
            <Fragment>
                <Navbar color="white" light expand="md" style={{padding:"30px",boxShadow:"2px 2px 5px black"}}>
                    <NavbarBrand href="/">
                        <div className={"logoClass"} >
                            <img src={"/assets/img/CadsBig.png"} height={"inherit"} width={"160px"}/>
                        </div>
                    </NavbarBrand>
                </Navbar>
                <div style={{backgroundColor:"#f3f1f140"}} className={"container-fluid"}>

                    <Row>
                        <Col xl={6} className={"padding-around"}>
                            <Card className={"card-style"}>
                                <CardBody>
                                    <div className={"mb-3"}>
                                        <Row>
                                            <Col xl={6}><span className={"fontHead"}>No. of Employess By Learning Path</span></Col>
                                            <Col xl={3}><span className={"CSV-button"}><Button size={"sm"}>export CSV</Button></span></Col>
                                            <Col xl={3}>
                                                <Input type="select" name="select" id="exampleSelect">
                                                    <option>By Dept</option>
                                                    <option>By Learning Path</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <LearningPath/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={6}  className={"padding-around"}>
                            <Card className={"card-style"}>
                                <CardBody>
                                    <div className={"mb-3"}>
                                        <Row>
                                            <Col xl={6}><span className={"fontHead"}>No. of Employess By Module</span></Col>
                                            <Col xl={3}><span className={"CSV-button"}><Button size={"sm"}>export CSV</Button></span></Col>
                                            <Col xl={3}>
                                                <Input type="select" name="select" id="exampleSelect">
                                                    <option>By Dept</option>
                                                    <option>By Learning Path</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={"mt-4"}>
                                        <ModulePath/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/*<hr/>*/}
                    <Row className={"mt-4"}>
                        <Col className={"padding-around"} xl={6}>
                            <Card className={"card-style"}>
                                <CardBody>
                                    <div className={"mb-3"}>
                                        <Row>
                                            <Col xl={6}><span className={"fontHead"}>Learning Path Completion By Employee</span></Col>
                                            <Col xl={3}><span className={"CSV-button"}><Button size={"sm"}>export CSV</Button></span></Col>
                                            <Col xl={3}>
                                                <Input type="select" name="select" id="exampleSelect">
                                                    <option>By Dept</option>
                                                    <option>By Employee</option>
                                                    <option>By Learning Path</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <PathCompletion/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className={"padding-around"} xl={6}>
                            <Card className={"card-style"}>
                                <CardBody>
                                    <div className={"mt-3 mb-3"}>
                                        <Row>
                                            <Col xl={6}><span className={"fontHead"}>Top Skills in organization</span></Col>
                                            <Col xl={3}><span className={"CSV-button"}><Button size={"sm"}>export CSV</Button></span></Col>
                                            <Col xl={3}>
                                                <Input type="select" name="select" id="exampleSelect">
                                                    <option>By Dept</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <OrganizationSkill/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/*<hr/>*/}
                    <Row className={"mt-4"}>
                        {/*<Col xl={3}>*/}

                        {/*</Col>*/}
                        <Col className={"padding-around"} xl={12}>
                            <Card className={"card-style"}>
                                <CardBody>
                                    <div className={"mb-3"}>
                                        <Row>
                                            <Col xl={6}><span className={"fontHead"}>Assessment Status</span></Col>
                                            <Col xl={3}><span className={"CSV-button"}><Button size={"sm"}>export CSV</Button></span></Col>
                                            <Col xl={3}>
                                                <Input type="select" name="select" id="exampleSelect">
                                                    <option>By Dept</option>
                                                    <option>By Date</option>
                                                    <option>By Assessment</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <AssessmentStatus/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {/*<Col xl={3}>*/}

                        {/*</Col>*/}
                    </Row>
                    {/*<hr/>*/}
                    <Row className={"mt-4"}>
                        <Col  className={"padding-around"} xl={6}>
                            <Card className={"card-style"}>
                                <CardBody>
                                    <div className={"mb-3"}>
                                        <Row>
                                            <Col xl={6}><span className={"fontHead"}>Job Metrics</span></Col>
                                            <Col xl={3}><span className={"CSV-button"}><Button size={"sm"}>export CSV</Button></span></Col>
                                            <Col xl={3}>
                                                <Input type="select" name="select" id="exampleSelect">
                                                    <option>By Week</option>
                                                    <option>By Month</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <JobMetrices/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className={"padding-around"} xl={6}>
                            <Card className={"card-style"}>
                                <CardBody >
                                    <div className={"mb-3"}>
                                        <Row>
                                            <Col xl={6}><span className={"fontHead"}>Project Metrics</span></Col>
                                            <Col xl={3}><span className={"CSV-button"}><Button size={"sm"}>export CSV</Button></span></Col>
                                            <Col xl={3}>
                                                <Input type="select" name="select" id="exampleSelect">
                                                    <option>By Week</option>
                                                    <option>By Month</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <ProjectMetrics/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        )
    }

}
