import React, { Component, Fragment } from "react";
import BlockUi from "react-block-ui";
import { Typeahead } from "react-bootstrap-typeahead";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Alert, Button, FormGroup, Input, Label } from "reactstrap";
import {
  registerB2CIndividual
} from "../../service/invitationService";
import {
  registerOrganizationKC,
  searchIndustry
} from "../../service/userService";
import showToast from "../../ToastMessage";
import { validate as validateEmail } from 'email-validator';

function checkNullString(str) {
  return str && str.trim().length > 0;
}

export default class RegFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountType: "individual",
      industryList: [],
      errorMessage: {
        fnameMsg: false,
        lnameMsg: false,
        emailMsg: false,
        invalidEmailMsg: false,
        passwordMsg: false,

        orgNameMsg: false,
        orgEmailMsg: false,
        invalidOrgEmailMsg: false,
        orgPasswordMsg: false,
        orgSelectedIndustryMsg: false,
      }
    };
  }

  handleOnChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errorMessage: {
        fnameMsg: false,
        lnameMsg: false,
        emailMsg: false,
        invalidEmailMsg: false,
        passwordMsg: false,

        orgNameMsg: false,
        orgEmailMsg: false,
        invalidOrgEmailMsg: false,
        orgPasswordMsg: false,
        orgSelectedIndustryMsg: false,
      }
    });
  };

  register = () => {
    const { accountType, firstName, lastName, email, password, errorMessage } = this.state;
    if (accountType === "individual") {
      if (!checkNullString(firstName)) {
        errorMessage.fnameMsg = true
        this.setState({
          errorMessage
        })
      } else {
        if (!checkNullString(lastName)) {
          errorMessage.lnameMsg = true
          this.setState({
            errorMessage
          })
        } else {
          if (!checkNullString(email)) {
            errorMessage.emailMsg = true
            this.setState({
              errorMessage
            })
          } else {
              if (!validateEmail(email)) {
                errorMessage.invalidEmailMsg = true
                this.setState({
                  errorMessage
                })
            } else {
              if (!checkNullString(password)) {
                errorMessage.passwordMsg = true
                this.setState({
                  errorMessage
                })
              } else {
                let user = {
                  fname: firstName,
                  lname: lastName,
                  email: email,
                  password: password,
                };
                this.setState({
                  blocking: true,
                });
                registerB2CIndividual(user).then((response) => {
                  if (response && response.status === 1) {
                    this.setState({
                      blocking: true,
                      isError: false,
                    });
                    showToast(response.message);
                    setTimeout(() => {
                      window.location.href = "/home";
                    }, 2500);
                  } else {
                    this.setState({
                      blocking: false,
                      isError: true,
                      errorMessage:
                        response.message ||
                        "Failed To register, please try again after some time.",
                    });
                  }
                });
              }
            }
          } 
        }
      }
      // } else {
      //   warningToast("Please fill all required fields");
    } else {
      this.registerOrganization();
    }
  };

  registerOrganization = () => {
    const { orgName, orgEmail, orgPassword, orgSelectedIndustry, errorMessage } = this.state;
    let industry = ""
    if (orgSelectedIndustry) {
      industry = [...orgSelectedIndustry]
      industry = industry[0]
    } 
    if (!checkNullString(orgName)) {
      errorMessage.orgNameMsg = true
      this.setState({
        errorMessage
      })
    } else {
      if (!checkNullString(orgEmail)) {
        errorMessage.orgEmailMsg = true
        this.setState({
          errorMessage
        })
      } else {
        if (!validateEmail(orgEmail)) {
          errorMessage.invalidOrgEmailMsg = true
          this.setState({
            errorMessage
          })
        } else {
          if (!checkNullString(orgPassword)) {
            errorMessage.orgPasswordMsg = true
            this.setState({
              errorMessage
            })
          } else {
            if (!checkNullString(industry.name)) {
              errorMessage.orgSelectedIndustryMsg = true
              this.setState({
                errorMessage
              })
            } else {
              this.setState({
                blocking: true,
              });
              registerOrganizationKC(
                orgName,
                orgEmail,
                orgPassword,
                orgSelectedIndustry[0].id
              ).then((res) => {
                showToast("Organization Registered");
                if (res && res.status === 1) {
                  this.setState({
                      blocking: false,
                      isError: false,
                  });
                  // showToast(res.message);
                  setTimeout(() => {
                    window.location.href = "/home";
                  }, 2500);
                } else {
                    this.setState({
                        blocking: false,
                        isError: true,
                        errorMessage:
                            res.message ||
                            "Failed To register, please try again after some time.",
                    });
                }
              });
            }
          }
        }
      } 
    }
    // } else {
    //   warningToast("Fill all required fields.");
  };

  handleIndustrySearch = (text) => {
    if (text && text.trim().length > 0) {
      this.setState({
        industryList: [],
      });
      this.setState({
        isLoading: true,
      });
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.searchIndustry(text.trim()), 900);
    } else {
      clearTimeout(this.timer);
      this.setState({
        isLoading: false,
        industryEmptyLabel: "Type to search...",
        industryList: [],
      });
    }
  };

  searchIndustry = (query) => {
    this.setState({
      isLoading: true,
    });
    searchIndustry(query).then((response) => {
      this.setState({
        isLoading: false,
      });
      if (response && response.status) {
        this.setState({
          industryList: response.data,
          industryEmptyLabel: "No industry found",
        });
      } else {
        this.setState({
          countryList: [],
          industryEmptyLabel: "No industry found",
        });
      }
    });
  };

  changeOrgIndustry = (orgSelectedIndustry) => {
    this.setState({
      orgSelectedIndustry,
    });
  };

  render() {
    const { firstName, lastName, email, password, orgName, orgEmail, orgPassword, errorMessage } = this.state;
    return (
      <Fragment>
        <ToastContainer />
        <BlockUi
          tag="div"
          className={"h-100"}
          blocking={this.state.blocking}
          loader={
            <Loader
              type="Oval"
              color="grey"
              height={50}
              width={50}
              timeout={13000}
              className="h-100"
            />
          }
        >

          <div className="d-flex align-items-center justify-content-center h-100" style={{flexFlow: "column"}}>
            {this.state.isError && (
              <div className={"text-center"}>
                    <Alert color={"danger"}>{this.state.errorMessage}</Alert>
                </div>
            )}
            <div>
              <div className="text-center main-form">
                <b>Sign Up</b>
                <br />
                <br />
                <p style={{ fontSize: "small", marginBottom: 5 }}>
                  Select account type
                </p>
                <div className="account-type">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="radio1"
                        defaultChecked="true"
                        onChange={() =>
                          this.setState({
                            accountType: "individual",
                            firstName: "",
                            lastName: "",
                            email: "",
                            password: "",
                            errorMessage: {
                              fnameMsg: false,
                              lnameMsg: false,
                              passwordMsg: false,
                              emailMsg: false,
                      
                              orgNameMsg: false,
                              orgEmailMsg: false,
                              orgPasswordMsg: false,
                              orgSelectedIndustryMsg: false,
                            }
                          })
                        }
                      />
                      Individual
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="radio1"
                        onChange={() =>
                          this.setState({
                            accountType: "organisation",
                            orgName: "",
                            orgEmail: "",
                            orgPassword: "",
                            errorMessage: {
                              fnameMsg: false,
                              lnameMsg: false,
                              passwordMsg: false,
                              emailMsg: false,
                      
                              orgNameMsg: false,
                              orgEmailMsg: false,
                              orgPasswordMsg: false,
                              orgSelectedIndustryMsg: false,
                            }
                          })
                        }
                      />
                      Organisation
                    </Label>
                  </FormGroup>
                </div>

                {this.state.accountType === "individual" ? (
                  <div>
                    <Input
                      className="mt-2"
                      placeholder="First Name"
                      name="firstName"
                      onChange={this.handleOnChange}
                      value={firstName}
                    />
                    <div className={errorMessage.fnameMsg ? "error_msg visible" : "error_msg invisible"}>Please enter First Name</div>
                    <Input
                      className="mt-2"
                      placeholder="Last Name"
                      name="lastName"
                      onChange={this.handleOnChange}
                      value={lastName}
                    />
                    <div className={errorMessage.lnameMsg ? "error_msg visible" : "error_msg invisible"}>Please enter Last Name</div>
                    <Input
                      className="mt-2"
                      placeholder="Email"
                      name="email"
                      onChange={this.handleOnChange}
                      value={email}
                    />
                    <div className={errorMessage.emailMsg || errorMessage.invalidEmailMsg ? "error_msg visible" : "error_msg invisible"}>
                      {errorMessage.emailMsg ? "Please enter Email address" : "Please enter a valid Email address"}
                    </div>
                    <Input
                      type={"password"}
                      className="mt-2"
                      placeholder="Password"
                      name="password"
                      onChange={this.handleOnChange}
                      value={password}
                    />
                    <div className={errorMessage.passwordMsg ? "error_msg visible" : "error_msg invisible"}>Please enter Password</div>
                  </div>
                ) : (
                  <div>
                    <Input
                      className="mt-2"
                      placeholder="Organisation Name"
                      name="orgName"
                      onChange={this.handleOnChange}
                      value={orgName}
                    />
                    <div className={errorMessage.orgNameMsg ? "error_msg visible" : "error_msg invisible"}>Please enter Organization Name</div>
                    <Input
                      className="mt-2"
                      placeholder="Email"
                      name="orgEmail"
                      onChange={this.handleOnChange}
                      value={orgEmail}
                    />
                    <div className={errorMessage.orgEmailMsg || errorMessage.invalidOrgEmailMsg ? "error_msg visible" : "error_msg invisible"}>
                      {errorMessage.orgEmailMsg ? "Please enter Organization Email address" : "Please enter a valid Organization Email address"}
                    </div>
                    <Input
                      type={"password"}
                      className="mt-2"
                      placeholder="Password"
                      name="orgPassword"
                      onChange={this.handleOnChange}
                      value={orgPassword}
                    />
                    <div className={errorMessage.orgPasswordMsg ? "error_msg visible" : "error_msg invisible"}>Please enter Organization Password</div>
                    {/*<p className={"mt-3"} style={{ fontSize: 'small', marginBottom: 5 }}>*/}
                    {/*	Select account type*/}
                    {/*</p>*/}
                    {/*<div*/}
                    {/*	className='organisation-type'*/}
                    {/*	style={{ display: 'flex' }}*/}
                    {/*>*/}
                    {/*	<div className='org-type'>*/}
                    {/*		<FormGroup check>*/}
                    {/*			<Label check>*/}
                    {/*				<Input*/}
                    {/*					type='radio'*/}
                    {/*					name='radio2'*/}
                    {/*					defaultChecked='true'*/}
                    {/*					onChange={() =>*/}
                    {/*						this.setState({*/}
                    {/*							organisationType: 'academia',*/}
                    {/*						})*/}
                    {/*					}*/}
                    {/*				/>*/}
                    {/*				Academia*/}
                    {/*			</Label>*/}
                    {/*		</FormGroup>*/}
                    {/*		<FormGroup check>*/}
                    {/*			<Label check>*/}
                    {/*				<Input*/}
                    {/*					type='radio'*/}
                    {/*					name='radio2'*/}
                    {/*					onChange={() =>*/}
                    {/*						this.setState({*/}
                    {/*							organisationType: 'company',*/}
                    {/*						})*/}
                    {/*					}*/}
                    {/*				/>*/}
                    {/*				Company*/}
                    {/*			</Label>*/}
                    {/*		</FormGroup>*/}
                    {/*	</div>*/}
                    {/*</div>*/}

                    <Typeahead
                      className={"mt-2"}
                      id={"industry-select"}
                      labelKey="name"
                      onChange={this.changeOrgIndustry}
                      multiple={false}
                      isLoading={this.state.isLoading}
                      selected={this.state.orgSelectedIndustry}
                      options={this.state.industryList}
                      placeholder={"Industry"}
                      onInputChange={this.handleIndustrySearch}
                      onFocus={(e) => {
                        if (
                          !e.target.value ||
                          e.target.value.trim().length === 0
                        ) {
                          this.setState({
                            industryEmptyLabel: "Type to search...",
                          });
                        } else {
                          this.handleIndustrySearch(e.target.value);
                        }
                      }}
                      onBlur={() => {
                        if (
                          !(
                            this.state.orgSelectedIndustry &&
                            this.state.orgSelectedIndustry.hasOwnProperty(0)
                          )
                        ) {
                          this.setState({
                            industrySelectErr: true,
                          });
                        } else {
                          this.setState({
                            industrySelectErr: false,
                          });
                        }
                      }}
                      emptyLabel={
                        this.state.isLoading
                          ? "Searching ..."
                          : this.state.industryEmptyLabel
                      }
                      // onSearch={this.searchIndustry}
                    />
                    {this.state.industrySelectErr &&
                      !(
                        this.state.orgSelectedIndustry &&
                        this.state.orgSelectedIndustry.hasOwnProperty(0)
                      ) && (
                        <p className={"font-14 red"}>
                          Please select valid industry from list.
                        </p>
                      )}
                      <div className={errorMessage.orgSelectedIndustryMsg ? "error_msg visible" : "error_msg invisible"}>Please select Industry</div>
                  </div>
                )}
              </div>
              <div className="TandC">
                <p>
                  By signing up, you are agreeing to our
                  <br />
                  <strong>
                    <a href="/register">privacy policy</a>
                  </strong>{" "}
                  &{" "}
                  <strong>
                    <a href="/register">terms and conditions.</a>
                  </strong>
                </p>
              </div>
                <Button
                    className="mt-4 btn-class"
                    color="primary"
                    onClick={this.register}
                >
                    Sign up
                </Button>
                <Link className={"btn footerLink"} to={"/signin"}>
                    <Button
                        className={"mt-2"}
                        style={{
                            padding: "2px 40px",
                            border: "none",
                            fontWeight: "bold",
                            color: "#007aff",
                        }}
                        color="white"
                    >
                        Login
                    </Button>
                </Link>

            </div>

          </div>
        </BlockUi>
      </Fragment>
    );
  }
}
