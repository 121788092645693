import {
	faFacebook,
	faLinkedin,
	faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

class Footer extends React.Component {
	render() {
		return (
			<Fragment>
				<div className="container-fluid">
					<Row>
						<Col xl={"4"} style={{ color: "black" }}>
							<div style={{ padding: "9vh 9vh 0vh 9vh" }}>
								<div>
									<img
										src={"/assets/img/CadsBig.png"}
										height={"auto"}
										width={"50%"}
										alt=""
									/>
								</div>
							</div>
							<div style={{ padding: "0vh 9vh 9vh 9vh" }}>
								<div style={{ marginTop: "5vh" }}>
									<h6
										className={"fontWeight900"}
										style={{ fontSize: "smaller" }}
									>
										Singapore Office (HQ)
									</h6>
									<h6
										className={"fontWeight400"}
										style={{ color: "#728090", fontSize: "smaller" }}
									>
										6, Raffles Boulevard, Marina Square, #03-308, Singapore 039594.
									</h6>
								</div>

								<div style={{ marginTop: "5vh" }}>
									<h6
										className={"fontWeight900"}
										style={{ fontSize: "smaller" }}
									>
										Malaysia Office (Operations)
									</h6>
									<h6
										className={"fontWeight400"}
										style={{ color: "#728090", fontSize: "smaller" }}
									>
										Level 8, Vertical Corporate Tower B, Avenue 10, The
										Vertical, No. 8 Jalan Kerinchi, Bangsar South City, 59200,
										Kuala Lumpur
									</h6>
								</div>
								<div style={{ fontSize: "small", marginTop: "5%" }}>
									<FontAwesomeIcon icon={faFacebook} /> &nbsp;
									<FontAwesomeIcon icon={faLinkedin} /> &nbsp;
									<FontAwesomeIcon icon={faYoutube} />
								</div>
							</div>
						</Col>
						<Col
							xl={"8"}
							style={{
								padding: "9vh 9vh 9vh 9vh",
								backgroundColor: "#152234",
								color: "grey",
							}}
						>
							<Row style={{ marginBottom: "2%" }}>
								<Col xl={6}>
									<h6 className={"footer"}>
										<span className={"footerLink"} >
											About
										</span>
									</h6>
									<h6 className={"footer"}>
										<span className={"footerLink"}>
											Solutions
										</span>
									</h6>
									<h6 className={"footer"}>
										<span className={"footerLink"}>
											Features
										</span>
									</h6>
									<h6 className={"footer"}>
										<span className={"footerLink"}>
											Enterprise
										</span>
									</h6>
									<h6 className={"footer"}>
										<span className={"footerLink"}>
											Pricing
										</span>
									</h6>
									<h6 className={"footer"}>
										<span className={"footerLink"}>
											Security
										</span>
									</h6>
									<h6 className={"footer"}>
										<span className={"footerLink"}>
											Brand
										</span>
									</h6>
									<h6 className={"footer"}>
										<span className={"footerLink"}>
											Policy
										</span>
									</h6>
									<h6 className={"footer"}>
										<span className={"footerLink"}>
											Community Guidelines
										</span>
									</h6>
								</Col>
							</Row>
							<hr style={{ borderTop: "1px solid white", marginBottom: "2%" }} />
							<Row>
								<Col xl={12}>
									<h6 style={{ fontSize: "smaller" }}>
										Copyright 2020 The Center of Applied Data Science. — Privacy
										Policy. Terms and Conditions.
									</h6>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</Fragment>
		);
	}
}

export default Footer;
