import React, {Fragment} from 'react';
import Cookies from "universal-cookie";
import {REDIRECTION_URL, SKILLSTREET_REDIRECTION} from "../../constants/urlConstants";
import {connect} from "react-redux";
import {keycloak} from '../../App'

class LogOut extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.logoutUser();
    }

    logoutUser = () => {
        const cookie = new Cookies();
        // console.log("keycloak ",this.props.keycloak)
        cookie.remove("access_token",{path: '/', httpOnly: false})
        cookie.remove("refresh_token",{path: '/', httpOnly: false})
        keycloak.init({onLoad: "check-sso"}).then(auth => {
            keycloak.logout({redirectUri: REDIRECTION_URL})
        }).catch(err => {
            window.location.href = "/";
        })

    }

    render() {
        return(
            <Fragment>

            </Fragment>
        )
    }
}


const mapStateToProp = state => ({
    keycloak:state.customReducer.keycloak
});

export default connect(mapStateToProp)(LogOut) ;
