import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";
import "./style.scss";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const list = [
  {
    id: 1,
    image: "/assets/img/carousel/1@2x.png",
    title: "C Suite",
    text:
      "We are transitioning into a tech company under our digital transformation initiatives. However our efforts are sub-par even though we implemented the right technologies. What can we do to become a data-driven organization?",
    link: "http://www.google.com",
  },
  {
    id: 2,
    image: "/assets/img/carousel/4@2x.png",
    title: "Human Capital",
    text:
    "Change is happening at your workplace at an accelerated pace. The attrition rate of employees are increasing due to automation and lack of skills. Not knowing the data literacy level of the workforce scares me. How should we future proof the workforce to tackle future challenges?",
    link: "http://www.google.com",
  },
  {
    id: 3,
    image: "/assets/img/carousel/3@2x.png",
    title: "Data Professional",
    text:
    "Data scientist, data engineer, data analysts. These are what I am known for. I know that my skills are in demand, and my community is huge. As a data practitioner, where can I go to find resources in an all-in-one place for me everything data-related?",
    link: "http://www.google.com",
  },
  {
    id: 4,
    image: "/assets/img/carousel/2@2x.png",
    title: "Students & New Graduates",
    text:
      "I'm graduating, but I haven't received a job offer yet. The employers told me that I have inadequate data literacy skills to help them. What should I do to become employable?",
    link: "http://www.google.com",
  },
];

export default class ImageCarouselComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
    };
  }

  toggleImage = (tab) => {
      this.setState({
          current: tab
      })
  }

  render() {
    const { current } = this.state;
    return (
      <Fragment>
        <Row>
          <Col md="12" className="remove-col-pad">
            <div className="carousel-main">
              <Row>
                <Col md="4" className="viewDesktop">
                  <div className="carousel-image">
                    <img className="carousel-img" src={list[current].image} />
                  </div>
                  <div className="carousel-tabs">
                    {list.map((item, index) => (
                      <div
                        className={`carousel-tab-select ${
                          index == current && "carousel-tab-selected"
                        }`}
                        onClick={() => this.toggleImage(index)}
                      />
                    ))}
                  </div>
                </Col>
                <Col md="8">
                  <div className="carousel-text">
                    <div>
                      <h3 className="carousel-text-title">
                        {list[current].title}
                      </h3>
                      <p className="carousel-text-content">{list[current].text}</p>
                      <a style={{cursor: "default"}}>
                        <h5 className="carousel-text-content-learn"><p>Learn More <span className="viewMobileArrow" ><FontAwesomeIcon icon={faArrowRight} /></span> </p></h5>
                      </a>
                    </div>
                  </div>
                </Col>
                {/* to display tabs in mobile */}
                <Col md="4" className="viewMobile">
                  <div className="carousel-tabs">
                    {list.map((item, index) => (
                      <div
                        className={`carousel-tab-select ${
                          index == current && "carousel-tab-selected"
                        }`}
                        onClick={() => this.toggleImage(index)}
                      />
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
