import React, {Fragment} from "react";
import {Row, Col, Button, Input} from "reactstrap";
import {HorizontalBar} from "react-chartjs-2";

export default class PathCompletion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            learningPathCompletion:[]
        }
    }

    componentDidMount() {
        let learningPathCompletion = {
            labels: [
                "Employee 4",
                "Employee 3",
                "Employee 2",
                "Employee 1",
            ],
            datasets: [
                {
                    label: "EDS",
                    backgroundColor: "rgb(255,221,99)",
                    borderColor: "rgb(255,221,99)",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgb(255,221,99)",
                    hoverBorderColor: "rgb(255,221,99)",
                    data: [0, 0, 0, 0]
                },

                {
                    label: "EDA",
                    backgroundColor: "rgba(91,102,81,0.36)",
                    borderColor: "rgba(91,102,81,0.36)",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(91,102,81,0.36)",
                    hoverBorderColor: "rgba(91,102,81,0.36)",
                    data: [0, 50, 0, 0]
                },
                {
                    label: "AEDA",
                    backgroundColor: "rgba(184,83,12,0.9)",
                    borderColor: "rgba(184,83,12,0.9)",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(184,83,12,0.9)",
                    hoverBorderColor: "rgba(184,83,12,0.9)",
                    data: [15, 79, 0, 0]
                },
                {
                    label: "EDP",
                    backgroundColor: "rgba(99,133,255,0.6)",
                    borderColor: "rgba(99,133,255,0.6)",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(99,133,255,0.6)",
                    hoverBorderColor: "rgba(99,133,255,0.6)",
                    data: [79, 79, 50, 41]
                }
            ]
        };

        let options= {
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                        ticks: {
                        callback:function(d) {
                            return d +"%";
                        },
                        max: 120,
                        min: 0,
                        stepSize: 20
                    }
                }]
            },

        };

        this.setState({
            learningPathCompletion:learningPathCompletion,
            options:options
        })
    }

    render() {
        return(
            <div className={"hide-overflow"}>
                <HorizontalBar data={this.state.learningPathCompletion} options={this.state.options}/>
            </div>
        )
    }
}
