import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import "./SignUpPage.scss";
import Logo from "./Acceltic_White.svg";
import Rubiqe_Logo from "./RUBIQE.png";
import showToast from "../../ToastMessage";

const SignUpPage = () => {
    const copy = () => {
        console.log("copy");
        let textField = document.createElement("textarea");
        textField.innerText = "engage@thecads.com";
        document.body.appendChild(textField);
        textField.select();
        showToast("Email Copied to Clipboard !");
        document.execCommand("copy");
        textField.remove();
    };

    return (
        <div className="SignUpPage">
            <Row className="h-100">
                <Col className="left__container viewAccelticDivDesktop">
                    <div className="heading">
                        <img src={"https://general-assets.sgp1.digitaloceanspaces.com/rubiqe-acceltic-logos/acceltic-alt.png"} alt="Logo" />
                    </div>
                    {/*<div className="description">*/}
                    {/*    Accelerate Digital Transformation with Data Driven Automation.*/}
                    {/*</div>*/}
                    <a
                        href="mailto:engage@thecads.com"
                        className="contact_sales"
                    >
                        <div className="details__container">
                            For a new account, <b>Contact our sales team</b>
                        </div>
                        <div className="arrow__container">
                            <div className="arrow_line"></div>
                            <div className="arrow_pointer"></div>
                        </div>
                    </a>
                    <div onClick={() => copy()} className="email__container">
                        <u>engage@thecads.com</u>
                    </div>
                </Col>
                <Col className="right__container">
                    <div className="heading">
                        <img src={"https://general-assets.sgp1.digitaloceanspaces.com/rubiqe-acceltic-logos/rubiqe-alt.png"} alt="Logo"/>
                    </div>
                    {/*<div className="description">*/}
                    {/*    Future Skills for Future Careers.*/}
                    {/*</div>*/}
                    <Link to="/register" className="signUp__btn">
                        Sign Up
                    </Link>
                </Col>
                <Col className="left__container viewAccelticDivMobile">
                    <div className="heading">
                        <img src={Logo} alt="Logo" />
                    </div>
                    <div className="description viewAccelticDivDesktop">
                        Accelerate Digital Transformation with Data Driven Automation.
                    </div>
                    <div className="description viewAccelticDivMobile">
                        Data Literacy Talent Management
                    </div>
                    <a
                        href="mailto:engage@thecads.com"
                        className="contact_sales"
                    >
                        <div className="details__container">
                            For a new account, <b>Contact our sales team</b>
                        </div>
                        <div className="arrow__container">
                            <div className="arrow_line"></div>
                            <div className="arrow_pointer"></div>
                        </div>
                    </a>
                    <div onClick={() => copy()} className="email__container">
                        <u>engage@thecads.com</u>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default SignUpPage;
