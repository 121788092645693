import React, {Fragment} from "react";
import {Row, Col, Button, Input} from "reactstrap";
import {Line} from "react-chartjs-2";

export default class JobMetrices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobMetrics:[]
        }
    }

    componentDidMount() {


        let jobMetrics = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr'],
            datasets: [
                {
                    label: 'completed',
                    fill: false,
                    lineTension: 0.0,
                    backgroundColor: 'rgba(46,42,184,1)',
                    borderColor: 'rgba(46,42,184,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(46,42,184,1)',
                    pointBackgroundColor: 'rgba(46,42,184,1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(46,42,184,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 5,
                    pointHitRadius: 10,
                    data: [5, 19, 20, 10]
                },
                {
                    label: 'opened',
                    fill: false,
                    lineTension: 0.0,
                    backgroundColor: 'rgba(97,88,85,1)',
                    borderColor: 'rgba(97,88,85,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(97,88,85,1)',
                    pointBackgroundColor: 'rgba(97,88,85,1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(97,88,85,1)',
                    pointHoverBorderColor: 'rgba(97,88,85,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 5,
                    pointHitRadius: 10,
                    data: [70, 40, 65, 100]
                },
                {
                    label: 'Unopened',
                    fill: false,
                    lineTension: 0.0,
                    backgroundColor: 'rgba(182,81,27,1)',
                    borderColor: 'rgba(182,81,27,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(182,81,27,1)',
                    pointBackgroundColor: 'rgba(182,81,27,1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(182,81,27,1)',
                    pointHoverBorderColor: 'rgba(182,81,27,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 5,
                    pointHitRadius: 10,
                    data: [20, 15, 10, 65]
                }

            ]
        };

        let options= {
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    ticks: {
                        max: 500,
                        min: 0,
                        stepSize: 100
                    }
                }]
            },

        };


        this.setState({
            jobMetrics:jobMetrics,
            options:options
        })
    }

    render() {
        return(
            <div  className={"hide-overflow"}>
                <Line data={this.state.jobMetrics} options={this.state.options}/>
            </div>
        )
    }
}
