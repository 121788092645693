export const SET_ROLES = 'SET_ROLES';
export const SET_USER = 'SET_USER';
export const SET_SKILL_VERIFICATION_LINK = 'SET_SKILL_VERIFICATION_LINK';
export const SET_KEYCLOAK_ROLE = 'SET_KEYCLOAK_ROLE';
export const SET_KC_INSTANCE = 'SET_KC_INSTANCE';

export const setKcInstance = kc => ({
    type: SET_KC_INSTANCE,
    payload: { kc }
});export const setVerificationLink = link => ({
    type: SET_SKILL_VERIFICATION_LINK,
    payload: { link }
});
export const setRoles = roles => ({
    type: SET_ROLES,
    payload: { roles }
});

export const setUser = user => ({
    type: SET_USER,
    payload: { user }
});

export const setKeycloakRoles = role => ({
    type: SET_KEYCLOAK_ROLE,
    payload: { role }
});
