import React, {Fragment} from "react";
import {Row, Col, Button, Input} from "reactstrap";
import {HorizontalBar} from "react-chartjs-2";

export default class LearningPath extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            learningPath:[]
        }
    }

    componentDidMount() {
        let learningPath = {
            labels: ['EDS', 'EDA', 'AEDA', 'EDP'],
            datasets: [
                {
                    label: 'Employees',
                    backgroundColor: 'rgba(99,133,255,0.6)',
                    borderColor: 'rgb(99,133,255,0.6)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(99,133,255,0.6)',
                    hoverBorderColor: 'rgba(99,133,255,0.6)',
                    data: [50, 70, 80, 450]
                }
            ]
        };

        let options= {
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    ticks: {
                        max: 500,
                        min: 0,
                        stepSize: 100
                    }
                }]
            },
             // responsive: true,
            // maintainAspectRatio : false

        };

        this.setState({
            learningPath:learningPath,
            options : options
        })
    }

    render() {
        return(
            <div className={"hide-overflow"}>
                <HorizontalBar options={this.state.options} data={this.state.learningPath} />
            </div>
        )
    }
}
