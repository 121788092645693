import React, { useEffect, useState } from 'react';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Container, Modal } from 'reactstrap';
import { fetchPostData } from '../../service/postsData';
import './PublicNews.scss';

function PublicNews(props) {
	const [displayurl, setDisplayurl] = useState('');
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');

	const fetchData = () => {
		fetchPostData(props.atricleID).then((res) => {
			setDisplayurl(res.data.displayurl);
			setTitle(res.data.title);
			setDescription(res.data.description);
		});
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<Container>
			<Modal
				isOpen='true'
				size='lg'
				contentClassName='post-details-page'
				centered='false'
			>
				<div className='page-body'>
					<ArrowBackIcon
						className="back-icon"
						onClick={() => props.toggleModal()}
						style={{cursor: "pointer"}}
					/>
					<div className='body__image'>
						<div className='inner__container'>
							<img src={displayurl} alt='' />
						</div>
					</div>
					<div className='body__title'>
						<h3>{title}</h3>
					</div>
					<div className='body__description'>{description}</div>
				</div>
			</Modal>
		</Container>
	);
}

export default PublicNews;
