import {request} from "./requestService"
import {API_BASE_URL_SKILLSTREETS} from "../constants/urlConstants";

const GET_PROFILE_ACCESS = "/userDetails/getCurrentUserProfileAccess";
const GET_ALL_PROFILES_OF_USER = '/userDetails/getUserAllProfileDetails';
const SWITCH_ORG = '/userDetails/switch';

export function getProfileAccess() {
    return request({
        url: API_BASE_URL_SKILLSTREETS + GET_PROFILE_ACCESS,
        method: 'GET'
    })
}
export function getAllProfilesOfUser() {
    return request({
        url: API_BASE_URL_SKILLSTREETS + GET_ALL_PROFILES_OF_USER,
        method: 'GET'
    });
}


export function switchOrg(id) {
    return request({
        url: API_BASE_URL_SKILLSTREETS + SWITCH_ORG + '/' + id,
        method: 'POST'
    });
}