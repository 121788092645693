import React, {Fragment, Component} from 'react';
import {Input, Button, Alert} from 'reactstrap';
import {asyncContainer, Typeahead} from 'react-bootstrap-typeahead';

import {getDeparmentList, registerB2bIndividual, validateInvitationToken} from "../../service/invitationService";
import {getAllRole, searchCountry} from "../../service/userService";
import {ToastContainer} from "react-toastify";
import showToast, {warningToast} from "../../ToastMessage";
import Loader from "react-loader-spinner";
import BlockUi from "react-block-ui";
import Cookie from "universal-cookie";
import {BASE_COOKIE} from "../../constants/urlConstants";
import { validate as validateEmail } from 'email-validator';

const cookie = new Cookie();


function checkNullString (str)  {
    return str && str.trim().length > 0
}

export default class RegFields extends Component {
    constructor(props) {
        super(props)

        this.state = {
            countryList: [],
            roleList: [],
            departments: [],
            selectedCountry: null,
            selectedDepartment: null,
            selectedRole: null,
            blocking2: false,
            tags : "",
            orgId: null
        }
    }

    handleOnChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    handleOnEmailChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
        if (!validateEmail(this.state.email)) {
            this.setState({
                isInvalidEmail: true,
            })
        } else {
            this.setState({
                isInvalidEmail: false,
            })
        }
    };

    componentDidMount() {
        // this.getDepartments()
        this.validateInvitation();
        this.getJobLevel();
        cookie.remove("access_token",{path: '/', httpOnly: false, domain: BASE_COOKIE})
    }

    getJobLevel = () => {
        this.setState({
            blocking2: true
        })
        getAllRole().then(response => {
            if(response && response.status) {
                this.setState({
                    roleList: response.data,
                    blocking2: false
                })
            }
        }).catch((err) => {
            console.error("error while fetching job level", err)
            this.setState({
                blocking2: false
            })
        })
    }

    validateInvitation = () => {
        let search = new URLSearchParams(this.props.location.search);
        let token = search.get("token");
        let tenant = search.get("tenant");
        let code = search.get("code");
        let chd = search.get("chd");
        let orgId = search.get("orgId")
        this.setState({tags: chd, orgId: orgId})


        if(code && code.trim().length > 0) {
            const email = atob(code);
            if(validateEmail(email)) {
                this.setState({
                    emailDisabled: true,
                    email: email
                })
            }
        }
        this.setState({
            tenant,
            blocking: true
        })
        validateInvitationToken(tenant,token).then(response => {
            if(response && response.status === 1) {
                this.setState({
                    orgName: response.tenant[0].name,
                    orgEmail: response.tenant[0].email,
                    departments: response.departments.Departments,
                    endpoint: (response.endpoint ? response.endpoint.includes("http") ? response.endpoint : "https://" + response.endpoint : "") + "/api/Webservice"
                }, () => {
                    console.log(this.state)
                });
                // this.getDepartments(response.tenant[0].email)
            } else {
                this.setState({
                    invalidUrl: true
                })
            }
            this.setState({
                blocking: false
            })
        })
    };

    getDepartments = () => {
        getDeparmentList("stam@thecads.com").then(response => {
            if(response && response.status === 1) {
                this.setState({
                    departments: response.Departments || []
                })
            }
        })
    }

    handleCountrySearch = (text) => {
        if(text && text.trim().length > 0) {
            this.setState({
                countryList: []
            })
            this.setState({
                isLoading: true
            });
            clearTimeout(this.timer);
            this.timer = setTimeout(() => this.searchCountry(text.trim()), 900)
        } else {
            clearTimeout(this.timer);
            this.setState({
                isLoading: false,
                countryEmptyLabel: "Type to search...",
                countryList: []
            })
        }
    };

    searchCountry = (query) => {
        this.setState({
            isLoading: true
        });
        searchCountry(query).then(response => {
            this.setState({
                isLoading: false
            });
            if(response && response.status) {
                this.setState({
                    countryList: response.data,
                    countryEmptyLabel: "No country found"
                })
            } else {
                this.setState({
                    countryList: [],
                    countryEmptyLabel: "No country found"
                })
            }
        })
    };


    changeCountry = (selectedCountry) => {
        this.setState({
            selectedCountry
        })
    };

    registerIndividual = () => {
        this.setState({
            isFormErr: false,
            isInvalidEmail: false
        })
        if(checkNullString(this.state.firstName) && checkNullString(this.state.lastName)
            && checkNullString(this.state.email) && checkNullString(this.state.designation)
            && checkNullString(this.state.mobile) && checkNullString(this.state.location)
            // && checkNullString(this.state.identityNo) && checkNullString(this.state.identityNo)
            && this.state.department && this.state.selectedCountry && this.state.selectedCountry.hasOwnProperty(0)
            && this.state.role
        ) {
            if (!validateEmail(this.state.email)) {
                this.setState({
                    isInvalidEmail: true,
                    isFormErr: true
                })
            } else {
                let user = {
                    "email": this.state.email,
                    "fname": this.state.firstName,
                    "lname": this.state.lastName,
                    "department_id": this.state.department,
                    "job_title": this.state.designation,
                    "phone_mobile": this.state.mobile,
                    "identityNumber": this.state.identityNo || "",
                    "location": this.state.location,
                    "countryId": this.state.selectedCountry[0].id,
                    "country": this.state.selectedCountry[0].name,
                    "tenant_id": this.state.tenant,
                    "jobLevelId": this.state.role,
                    "tags": this.state.tags,
                    "orgId": this.state.orgId,
                };
                this.setState({
                    blocking: true
                })
                registerB2bIndividual(this.state.endpoint, user).then(response => {
                    if(response && response.status === 1) {
                        showToast(response.message);
                        setTimeout(() => {
                            this.setState({
                                blocking: false,
                                isError: false,
                                errMessage: ""
                            })
                            window.location.href ="/home"
                        }, 2500)
                    } else {
                        this.setState({
                            isError: true,
                            errMessage: response.message,
                            blocking: false
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                    this.setState({
                        blocking: false
                    })
                })
            }
        } else {
            // warningToast("Please fill all required fields")
            this.setState({
                isFormErr: true
            })
            if (!validateEmail(this.state.email)) {
                this.setState({
                    isInvalidEmail: true,
                })
            }
        }
    }

    render() {
        const { isFormErr, isInvalidEmail } = this.state;
        return(
            <Fragment>
                <ToastContainer/>
                <BlockUi tag="div" className={"h-100"} blocking={this.state.blocking || this.state.blocking2} loader={<Loader
                    type="Oval"
                    color="grey"
                    height={50}
                    width={50}
                    timeout={13000} //3 secs
                    className={"h-100"}
                />} message="Loading..">

                    {this.state.invalidUrl ? "Invalid Invitation Url" :


                <div className={"d-flex align-items-center justify-content-center h-100"} style={{flexFlow: "column"}}>

                        {this.state.isError && (
                            <div className={"text-center"}>
                                <Alert color={"danger"}>{this.state.errMessage}</Alert>
                            </div>
                        )}
                        <div>
                    <div className={"text-center main-form"}>
                    <b>Welcome to {this.state.orgName}</b>
                    <p>Kindly Fill Information</p>
                    <Input  className={"mt-2"} placeholder={"First Name"} name={"firstName"} onChange={this.handleOnChange} />
                    {isFormErr && (!checkNullString(this.state.firstName)) && <div className={"error_msg visible"}>Please enter First Name</div>}
                    <Input className={"mt-2"} placeholder={"Last Name"} name={"lastName"} onChange={this.handleOnChange} />
                    {isFormErr && (!checkNullString(this.state.lastName)) && <div className={"error_msg visible"}>Please enter Last Name</div>}
                    <Input type={"select"} className={"mt-2"} plcehoder={"Role"} name={"role"} onChange={this.handleOnChange} >
                        <option value={null}>
                            Select Role
                        </option>
                        {this.state.roleList.map(item => (
                            <option value={item.id}>
                                {item.jobLevel}
                            </option>
                        ))
                        }
                    </Input>
                    {isFormErr && (!this.state.role) && <div className={"error_msg visible"}>Please Select Role</div>}
                    <Input
                        autoComplete="off"
                        className={"mt-2"} placeholder={"Designation"} name={"designation"} onChange={this.handleOnChange} />
                    {isFormErr && (!checkNullString(this.state.designation)) && <div className={"error_msg visible"}>Please enter Designation</div>}
                    <Input type={"select"} className={"mt-2"} plcehoder={"Department"} name={"department"} onChange={this.handleOnChange} >
                        <option value={null}>
                            Select Department
                        </option>
                        {this.state.departments.map(item => (
                            <option value={item.id}>
                                {item.name}
                            </option>
                        ))
                        }
                    </Input>
                    {isFormErr && (!this.state.department) && <div className={"error_msg visible"}>Please Select Department</div>}
                    <Input className={"mt-2"} value={this.state.email} disabled={this.state.emailDisabled} placeholder={"Email"} name={"email"} onChange={this.handleOnEmailChange}/>
                    {isFormErr && (!checkNullString(this.state.email)) && <div className={"error_msg visible"}>Please enter Email</div>}
                    {isFormErr && isInvalidEmail && checkNullString(this.state.email) && <div className={"error_msg visible"}>Please enter valid Email</div>}
                    <Input className={"mt-2"} placeholder={"Mobile Number"} name={"mobile"} onChange={this.handleOnChange}/>
                    {isFormErr && (!checkNullString(this.state.mobile)) && <div className={"error_msg visible"}>Please enter Mobile Number</div>}
                    {/*<Input className={"mt-2"} placeholder={"IC No/Passport No"} name={"identityNo"} onChange={this.handleOnChange}/>*/}
                    {/*{isFormErr && (!checkNullString(this.state.identityNo)) && <div className={"error_msg visible"}>Please enter IC No/Passport No</div>}*/}
                    <Input className={"mt-2"} placeholder={"Location"} name={"location"} onChange={this.handleOnChange}/>
                    {isFormErr && (!checkNullString(this.state.location)) && <div className={"error_msg visible"}>Please enter Location</div>}
                    <Typeahead
                        id={'country-select'}
                        className={"mt-2"}
                        isLoading={this.state.isLoading}
                        selected={this.state.selectedCountry}
                        placeholder={"Eg. Malaysia"}
                        onChange={this.changeCountry}
                        labelKey={"name"}
                        onInputChange={this.handleCountrySearch}
                        onFocus={(e) => {
                            if(!e.target.value || e.target.value.trim().length === 0) {
                                this.setState({
                                    countryEmptyLabel: "Type to search..."
                                })
                            } else {
                                this.handleCountrySearch(e.target.value)
                            }
                        }}
                        onBlur={() => {
                            if(!(this.state.selectedCountry && this.state.selectedCountry.hasOwnProperty(0))){
                                this.setState({
                                    countrySelectErr: true
                                })
                            } else {
                                this.setState({
                                    countrySelectErr: false
                                })
                            }
                        }}
                        emptyLabel={this.state.isLoading ? "Searching ..." : this.state.countryEmptyLabel}
                        // onSearch={this.searchCountry}
                        options={this.state.countryList}
                    />
                    {this.state.isFormErr && !(this.state.selectedCountry && this.state.selectedCountry.hasOwnProperty(0)) &&
                        <div className={"error_msg visible"} >Please select valid country from list.</div>
                    }

                    <Button className={"mt-4"} color={"info"} onClick={this.registerIndividual} style={{width:'100%',background:'#21CFBA',border:'#21CFBA'}}>
                        Register
                    </Button>
                    </div>
                </div>
                </div>
                    }
                </BlockUi>
            </Fragment>
        )
    }
}
