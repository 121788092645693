import Keycloak from "keycloak-js";
import React, { Fragment } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Col, Row } from "reactstrap";
import Cookies from "universal-cookie";
import "./assets/main/base.scss";
import {
  APHRODITE_UI,
  BASE_COOKIE,
  BASE_DOMAIN_1,
  KEYCLOAK_URL,
  REDIRECTION_URL
} from "./constants/urlConstants";
import { setRoles } from "./reducers/action";
import {
  getCurrentUserInfo,
  registerJobSeekerTS, registerJobSeekerTSMAB,
  registerJobSeekerTSPERKESO,
  registerOrganization,
  searchIndustry,
  setDatastar,
} from "./service/userService";
import { ToastContainer } from "react-toastify";
import BlockUi from "react-block-ui";
import Loader from "react-loader-spinner";
import "./load.css";
import { warningToast } from "./ToastMessage";
import { getAllProfilesOfUser, getProfileAccess, switchOrg } from "./service/profieService";
import Avatar from "@material-ui/core/Avatar";

const cookie = new Cookies();

let initOptions = {
  url: KEYCLOAK_URL,
  realm: "cads",
  clientId: "b2c-client",
  onLoad: "login-required",
};

export const keycloak = Keycloak(initOptions);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      displayOrg: false,
      orgSelectedIndustry: null,
      industryList: [],
      userRoles: [],
      loadingDisplay: false,
      selectProfileEnabled: false,
      isError: false
    };
  }

  removeCookie = () => {
    cookie.remove('access_token', { domain: BASE_COOKIE, path: '/' })
    cookie.remove('last_profile', { domain: BASE_COOKIE, path: '/' })
    cookie.remove('orgid', { domain: BASE_COOKIE, path: '/' })
    cookie.remove('subdomain', { domain: BASE_COOKIE, path: '/' })
  }

  logoutAll = () => {
    keycloak.logout({ redirectUri: REDIRECTION_URL })
    setTimeout(this.removeCookie, 1000)

  }

  isError = () => {
    this.setState({ isError: true, selectProfileEnabled: true })
  }
  componentWillMount() {
    cookie.remove("access_token", { domain: BASE_COOKIE, path: '/' });
    cookie.remove("last_profile", { domain: BASE_COOKIE, path: '/' });
    cookie.remove("subdomain", { domain: BASE_COOKIE, path: '/' });
    cookie.remove("orgid", { domain: BASE_COOKIE, path: '/' });
  }

  componentDidMount() {
    this.checkAuthenticated();
  }

  checkAuthenticated = async () => {
    let initOptions = {};

    if (window.location.href.includes("#login")) {
      let redirectUri = APHRODITE_UI + "/";
      if (window.location.href.includes("b2b")) {
        redirectUri = APHRODITE_UI + "/?acceltic";
      } else if (window.location.href.includes("b2c")) {
        redirectUri = APHRODITE_UI + "/?rubiqe";
      } else if (window.location.href.includes("mab")) {
        redirectUri = APHRODITE_UI + "/?mabh";
      }
      initOptions = { onLoad: "login-required", redirectUri };
    } else if (window.location.href.includes("#datastar")) {
      let redirectUri = APHRODITE_UI + "/app#datastar";
      initOptions = { onLoad: "login-required", redirectUri };
    } else {
      initOptions = { onLoad: "check-sso" };
    }

    const response = await keycloak.init(initOptions);
    if (!response) {
      window.location.href = "/home";
    } else {
      this.checkUser(response, keycloak);
    }
  };

  checkUser = async (response, keycloak) => {
    try {
      let datastar = window.location.href.includes("#datastar") ? true : false;
      console.log(BASE_COOKIE);
      cookie.set("access_token", keycloak.token, {
        httpOnly: false,
        path: "/",
        domain: BASE_COOKIE,
      });
      console.log(keycloak.token)
      await keycloak.loadUserProfile();
      if (
        keycloak.profile.attributes.data_star &&
        (keycloak.profile.attributes.data_star[0] ||
          keycloak.profile.attributes.data_star[0] === "true" ||
          keycloak.profile.attributes.data_star[0] === true) &&
        keycloak.profile.attributes.user_type &&
        keycloak.profile.attributes.user_type === "b2c_individual"
      ) {
        this.getAllProfiles();
      } else if (
        (keycloak.profile.attributes.user_type &&
          keycloak.profile.attributes.user_type[0].includes("b2b")) ||
        (keycloak.profile.attributes.registredAs &&
          (keycloak.profile.attributes.registredAs[0] === "acceltic_individual" ||
            keycloak.profile.attributes.registredAs[0] ===
            "acceltic_organization"))
      ) {
        if (keycloak.profile.attributes.is_mab_cognito && keycloak.profile.attributes.is_mab_cognito[0]) {
          getCurrentUserInfo()
            .then((response) => {
              console.log("getCurrentUserInfo", response);
              this.props.dispatch(setRoles(keycloak.profile.attributes.user_type));
              if (response && response.status === 200) {
                if (datastar && !response.data.datastar) {
                  setDatastar().then((datastarRespose) => {
                    this.getAllProfiles();
                  });
                } else {
                  this.getAllProfiles();
                }
              } else {
                let response1 = response.response && response.response.data;
                if (
                  response.hasOwnProperty("message") &&
                  response.data === "NOT_FOUND"
                ) {
                  response1 = response;
                }
                if (
                  response.hasOwnProperty("response") &&
                  response.response.hasOwnProperty("message")
                ) {
                  response1 = response.response.data;
                }
                this.handleRegistration(response1, keycloak);
              }
            })
            .catch((error) => {
              this.isError()
              console.log("getCurrentUserInfo:::::mab::::::", error)
            });
        } else {
          this.getAllProfiles();
        }
      } else if (
        keycloak.profile.attributes.user_type &&
        (keycloak.profile.attributes.user_type[0].includes("b2c") ||
          keycloak.profile.attributes.user_type[0].includes("perkeso"))
      ) {
        getCurrentUserInfo()
          .then((response) => {
            console.log("getCurrentUserInfo", response);
            this.props.dispatch(setRoles(keycloak.profile.attributes.user_type));
            if (response && response.status === 200) {
              // window.location.href = "https://rubiqe." + BASE_DOMAIN_1 + "/";
              if (datastar && !response.data.datastar) {
                setDatastar().then((datastarRespose) => {
                  this.getAllProfiles();
                });
              } else {
                this.getAllProfiles();
              }
            } else {

              let response1 = response.response && response.response.data;
              if (
                response.hasOwnProperty("message") &&
                response.data === "NOT_FOUND"
              ) {

                response1 = response;
              }

              if (
                response.hasOwnProperty("response") &&
                response.response.hasOwnProperty("message")
              ) {
                response1 = response.response.data;
              }
              this.handleRegistration(response1, keycloak);
            }
          })
          .catch((error) => {
            this.isError()
            console.log("getCurrentUserInfo:::::::::::", error)
          });
      } else if (
        keycloak.profile.attributes.registredAs &&
        keycloak.profile.attributes.registredAs[0] === "rubiqe_individual"
      ) {
        getCurrentUserInfo()
          .then((response) => {
            console.log("getCurrentUserInfo", response);
            this.props.dispatch(setRoles(keycloak.profile.attributes.user_type));
            if (response && response.status === 200) {
              if (datastar && !response.data.datastar) {
                setDatastar().then((datastarRespose) => {
                  // window.location.href = "https://rubiqe." + BASE_DOMAIN_1 + "/";
                  this.getAllProfiles();
                });
              } else {
                this.getAllProfiles();
              }
            } else {

              let response1 = response.response && response.response.data;
              if (
                response.hasOwnProperty("message") &&
                response.data === "NOT_FOUND"
              ) {

                response1 = response;
              }

              if (
                response.hasOwnProperty("response") &&
                response.response.hasOwnProperty("message")
              ) {
                response1 = response.response.data;
              }
              this.handleRegistration(response1, keycloak);
            }
          })
          .catch((error) => {
            this.isError()
            console.log("getCurrentUserInfo:::::::::::", error)
          });
      }
    } catch (error) {
      this.isError()
      console.log("overAll:::::::::::", error)
    }
  };

  handleRegistration = (response, keycloak) => {
    let datastar = window.location.href.includes("#datastar") ? true : false;
    if (
      response.message == "User is not registered with skillstreets" ||
      response.message == "User is not found with id"
    ) {
      if (
        (keycloak.profile.attributes.user_type &&
          (keycloak.profile.attributes.user_type[0] == "b2c_individual" ||
            keycloak.profile.attributes.user_type[0] == "perkeso_user")) ||
        (keycloak.profile.attributes.registredAs &&
          keycloak.profile.attributes.registredAs[0] === "rubiqe_individual") ||
        (keycloak.profile.attributes.registredAs &&
          keycloak.profile.attributes.registredAs[0] === "acceltic_individual")
      ) {
        this.setState({
          loadingDisplay: true,
        });
        if (
          keycloak.profile.attributes.variation &&
          keycloak.profile.attributes.variation[0].includes("perkeso")
        ) {

          registerJobSeekerTSPERKESO(
            keycloak.profile.firstName,
            keycloak.profile.lastName,
            keycloak.profile.email,
            keycloak.profile.attributes.subdmain[0]
          ).then((response) => {
            if (response && response.status === 1) {
              this.getAllProfiles();
            } else {
              warningToast(response.message);
            }
          });
        } else {
          if (keycloak.profile.attributes && keycloak.profile.attributes.is_mab_cognito && keycloak.profile.attributes.is_mab_cognito[0]) {
            cookie.set("last_profile", "acceltic_individual");
            cookie.set("subdomain", keycloak.profile.attributes.subdmain[0], { domain: BASE_COOKIE });
            registerJobSeekerTSMAB(
              keycloak.profile.firstName,
              keycloak.profile.lastName,
              keycloak.profile.email,
              datastar
            ).then((response) => {
              if (response && response.status === 1) {
                cookie.set("firstVisit", true, { domain: BASE_COOKIE });
                cookie.set("showTour", "no", { domain: BASE_COOKIE });
                // window.location.href = "https://rubiqe." + BASE_DOMAIN_1 + "/";
                this.getAllProfiles();
              } else {
                warningToast(response.message);
              }
            });
          } else {
            console.log("inside else for registration as normal TS user")
            registerJobSeekerTS(
              keycloak.profile.firstName,
              keycloak.profile.lastName,
              keycloak.profile.email,
              datastar
            ).then((response) => {
              if (response && response.status === 1) {
                cookie.set("firstVisit", true, { domain: BASE_COOKIE });
                cookie.set("showTour", "no", { domain: BASE_COOKIE });
                // window.location.href = "https://rubiqe." + BASE_DOMAIN_1 + "/";
                this.getAllProfiles();
              } else {
                warningToast(response.message);
              }
            });
          }
        }
      } else if (
        keycloak.profile.attributes.user_type &&
        keycloak.profile.attributes.user_type[0] == "b2c_organization"
      ) {
        this.setState(
          {
            // displayOrg: true,
            orgFname: keycloak.profile.firstName,
            orgLname: keycloak.profile.lastName,
            orgEmail: keycloak.profile.email,
          },
          () => {
            this.submitOrg();
          }
        );
      }
    } else {
      // window.location.href = "/";
    }
  };

  handleIndustrySearch = (text) => {
    if (text && text.trim().length > 0) {
      this.setState({
        industryList: [],
      });
      this.setState({
        isLoading: true,
      });
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.searchIndustry(text.trim()), 900);
    } else {
      clearTimeout(this.timer);
      this.setState({
        isLoading: false,
        industryEmptyLabel: "Type to search...",
        industryList: [],
      });
    }
  };

  searchIndustry = (query) => {
    this.setState({
      isLoading: true,
    });
    searchIndustry(query).then((response) => {
      this.setState({
        isLoading: false,
      });
      if (response && response.status) {
        this.setState({
          industryList: response.data,
          industryEmptyLabel: "No industry found",
        });
      } else {
        this.setState({
          countryList: [],
          industryEmptyLabel: "No industry found",
        });
      }
    });
  };

  changeOrgIndustry = (orgSelectedIndustry) => {
    this.setState({
      orgSelectedIndustry,
    });
  };

  toogleOrgFlag = (flag) => {
    this.setState({
      isCompany: flag,
    });
  };

  submitOrg = () => {
    let organizationTypeId = 2;

    registerOrganization(
      this.state.orgFname,
      this.state.orgLname,
      this.state.orgEmail,
      organizationTypeId
    ).then((response) => {
      if (response && response.status) {
        cookie.set("firstVisit", true, { domain: BASE_COOKIE });
        cookie.set("showTour", "no", { domain: BASE_COOKIE });
        window.location.href = "https://rubiqe." + BASE_DOMAIN_1 + "/";
      }
      this.setState({
        displayOrg: false,
        isAuthenticated: true,
        userRoles: keycloak.profile.attributes.user_type,
      });
    });
  };

  getAllProfiles = () => {
    getAllProfilesOfUser().then(response => {
      const profiles = response.data || [];
      this.setState({
        allProfiles: profiles
      });

      let currentProfile;
      if (profiles.length > 0) {
        profiles.forEach((item) => {
          if (item.current && item.rubiqe) {
            currentProfile = item;
            cookie.set('orgid', item.orgDetail.organization.id, {
              domain: BASE_COOKIE,
            });
            window.location.href = "https://rubiqe." + BASE_DOMAIN_1 + "/";
          }
          if (item.current && item.acceltic) {
            currentProfile = item;
            cookie.set('orgid', item.orgDetail.organization.id, {
              domain: BASE_COOKIE,
            });
            cookie.set('subdomain', item.subDomainName, {
              domain: BASE_COOKIE,
            });
            window.location.href = "https://acceltic." + BASE_DOMAIN_1 + "/";
          }
        });

        if (!currentProfile) {
          this.setState({
            selectProfileEnabled: true
          })
        }
      } else {
        this.isError()
        this.setState({ allProfiles: [], selectProfileEnabled: true })
      }

    }).catch((err) => {
      this.isError()
      this.setState({ allProfiles: [], selectProfileEnabled: true })
      console.log(err, ":::::::::")
    })
  };
  handleSelectAccount = (profile) => {
    console.log(profile)
    cookie.remove('last_profile');
    cookie.remove('subdomain');
    cookie.remove('orgid');
    switchOrg(profile.orgDetail.organization.id).then((res) => {
      if (res) {
        if (profile.rubiqe) {
          window.location.href = "https://rubiqe." + BASE_DOMAIN_1 + "/";
        } else if (profile.acceltic) {
          window.location.href = "https://acceltic." + BASE_DOMAIN_1 + "/";
        } else {
          this.setState({
            errorMessage: "Something went wrong, please contact support team"
          })
        }
      }
    })
  };
  render() {
    const { selectProfileEnabled, allProfiles, isError } = this.state;
    console.log("selectProfileEnabled", selectProfileEnabled)
    return (
      <Fragment>
        {selectProfileEnabled ? (
          <div
            className="container-fluid pl-0"
            style={{ width: "100%", backgroundColor: "white" }}
          >
            <Row>
              <Col md={"6"}>
                <div
                  className={
                    "logo-div d-flex align-items-center justify-content-center"
                  }
                  style={{ height: "100vh" }}
                >
                  <img
                    src={require("./views/RegistrationPage/CADS-logo-color-slogan.png")}
                    height={"inherit"}
                    width={"220x"}
                    alt="CADS Logo"
                  />
                </div>
              </Col>
              <Col md={"6"}>
                <div className={"d-flex justify-content-center align-items-center h-100"}>
                  <div>
                    {!isError && allProfiles && allProfiles.length > 0 ?
                      <>
                        <div className={"select-account"}>
                          Select Account
                        </div>
                        {allProfiles.map(user => {
                          return <>
                            <div className={"d-flex select-profile-item"} onClick={() => this.handleSelectAccount(user)}>
                              <div>
                                <Avatar
                                  className={'dropdown_image_inr'}
                                  src={
                                    user.admin
                                      ? user.orgDetail.profilePhoto
                                      : user.profilePhoto
                                  }
                                  alt="profile pic"
                                />
                              </div>
                              <div className={"info"}>
                                <div>
                                  <label className={"name"}>
                                    {user.admin ? (
                                      <>{user.orgDetail.organization.name}</>
                                    ) : (
                                      <>
                                        {user.fname} {user.lname}
                                      </>
                                    )}
                                  </label>
                                  <span className={`type ${user.rubiqe ? "b2c" : "b2b"}`}>
                                    {user.rubiqe && (
                                      <p>{`Rubiqe ${user.admin ? 'organization' : 'individual'
                                        }`}</p>
                                    )}
                                    {user.acceltic && (
                                      <p>{`Acceltic ${user.admin ? 'organization' : 'individual'
                                        }`}</p>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        })
                        }
                      </>
                      :
                      <div className={"text-center"} style={{ height: '100vh', color: "#4e6174", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h5>Oh snap! The platform has not loaded correctly.
                          <br /><span >Please try to login again <a href="#" onClick={this.logoutAll}>click here</a></span>
                          <br /><div className={'mt-3'}>If problems still persist, pls contact <a href="#"> support@thecads.com</a></div>
                        </h5>
                      </div>
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <>
            {isError ?
              <div className={"text-center"} style={{ height: '100vh', color: "#4e6174", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h5>Oh snap! The platform has not loaded correctly.
                    <br /><span >Please try to login again <a href="#" onClick={this.logoutAll}>click here</a></span>
                    <br /><div className={'mt-3'}>If problems still persist, pls contact <a href="#"> support@thecads.com</a></div></h5>
              </div>
              :
              <div className="load">
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path
                      fill="#2E485C"
                      d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                    />
                  </svg>
                </div>
              </div>
            }
          </>
        )}
      </Fragment>
    );
  }
}

const mapStateToProp = (state) => ({
  roles: state.customReducer.roles,
});

export default withRouter(connect(mapStateToProp)(App));
