import {request, requestTalentSpy} from "../requestService";
import {API_BASE_URL_LANDING, API_BASE_URL_SKILLSTREETS_PUBLIC} from "../../constants/urlConstants";

const GET_WORLD_DATA = "/covid/country";
const GET_COUNTRY_DATA = "/covid/state";
const GET_TRENDS_DATA = "/article";
const GET_ANNOUNCEMENT_DATA = "/announcement";
const GET_PUBLIC_JOB_LIST = '/public/getAllJobsProjectList'


export async function getTrendsData(pageNumber) {
    return await requestTalentSpy({
        url: API_BASE_URL_LANDING + GET_TRENDS_DATA + "?pagenumber="+pageNumber+"&pagesize="+ 5,
        method: 'GET',
    })
}

export async function getPublicJobs(pageNo,pageSize) {
    return await request({
        url: API_BASE_URL_SKILLSTREETS_PUBLIC + GET_PUBLIC_JOB_LIST + '?pageNo=' + pageNo + '&pageSize=' + pageSize ,
        method: 'GET'
    })
}

export async function getWorldData() {
    return await requestTalentSpy({
        url: API_BASE_URL_LANDING + GET_WORLD_DATA,
        method: 'GET',
    })
}

export async function getAnnouncement() {
    return await requestTalentSpy({
        url: API_BASE_URL_LANDING + GET_ANNOUNCEMENT_DATA,
        method: 'GET',
    })
}

export async function getCountryData(countryId) {
    return await requestTalentSpy({
        url: API_BASE_URL_LANDING + GET_COUNTRY_DATA + "/" +countryId,
        method: 'GET',
        // body: JSON.stringify({
        //     "email": email,
        //     "password": password
        // })
    })
}
