import clsx from "clsx";
import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import RadarChart from "../../charts/RadarChart";
import LoginButton from "../LoginButton";

class Organization extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: "assessWorkForce",
		};
	}

	componentDidMount() {}

	handleClick = (event) => {
		let id = event.target.id;
		this.setState({ activeTab: id });
	};

	render() {
		return (
			<Fragment>
				<div className="container-fluid">
					<Row>
						<Col xl={"12"} className={"whiteHeading"}>
							<h5
								className={"fontWeight900"}
								style={{ fontSize: "large", color: "#a6b0b9" }}
							>
								FOR ORGANIZATION
							</h5>
							<h1
								className={"fontWeight900"}
								style={{ fontSize: "x-large", color: "#4e6074" }}
							>
								Futureproof the Workforce
							</h1>
						</Col>
					</Row>
					<Row>
						<Col
							xl={"7"}
							style={{ backgroundColor: "#fbfbfc" }}
							className={"org-right-col"}
						>
							{this.state.activeTab === "assessWorkForce" ? (
								<div>
									<div className={"org-right-div"}>
										<div className={"assessWorkforce"}></div>
										{/*<AssessWorkforce/>*/}
									</div>
								</div>
							) : (
								<></>
							)}
							{this.state.activeTab === "upSkillWorkForce" ? (
								<div className={"org-right-div"}>
									<div>
										<img
											src={"/assets/img/UpskillWorkforce.svg"}
											width={"100%"}
											height={"100%"}
											alt=""
										/>
										{/*<div className={"upskillWorkforce"}>*/}

										{/*</div>*/}
									</div>
								</div>
							) : (
								<></>
							)}
							{this.state.activeTab === "alignmentGap" ? (
								<div className={"org-right-div"}>
									<div>
										<img
											src={"/assets/img/second-right-bg.png"}
											width={"100%"}
											height={"100%"}
											alt=""
										/>
										{/*<div className={"upskillWorkforce"}>*/}

										{/*</div>*/}
									</div>
								</div>
							) : (
								<></>
							)}
						</Col>
						<Col xl={"4"} className={"common-left-menu"}>
							<div style={{ padding: "9vh 9vh 0vh 9vh" }}>
								<div>
									<img
										src={"/assets/img/lab.png"}
										height={"80px"}
										width={"70px"}
										alt=""
									/>
								</div>
							</div>
							<div style={{ padding: "0vh 7vh 9vh 5vh" }}>
								<div style={{ marginTop: "5vh" }}>
									<h3
										className={clsx("fontWeight700 pointerCursor", {
											"active-skills":
												this.state.activeTab === "assessWorkForce",
										})}
										id={"assessWorkForce"}
										onClick={this.handleClick}
									>
										Assess Workforce
									</h3>
									<h6
										className={clsx("fontWeight400", {
											"active-skills-data":
												this.state.activeTab === "assessWorkForce",
										})}
									>
										Assess the data literacy skills of the workforce.
									</h6>
								</div>
								<div style={{ marginTop: "5vh" }}>
									<h3
										className={clsx("fontWeight700 pointerCursor", {
											"active-skills":
												this.state.activeTab === "upSkillWorkForce",
										})}
										id={"upSkillWorkForce"}
										onClick={this.handleClick}
									>
										Upskill Workforce
									</h3>
									<h6
										className={clsx("fontWeight400", {
											"active-skills-data":
												this.state.activeTab === "upSkillWorkForce",
										})}
									>
										Upskill the workforce to become data literate.
									</h6>
								</div>
								<div style={{ marginTop: "5vh" }}>
									<h3
										className={clsx("fontWeight700 pointerCursor", {
											"active-skills": this.state.activeTab === "alignmentGap",
										})}
										id={"alignmentGap"}
										onClick={this.handleClick}
									>
										Understand Data Driven Strategy
									</h3>
									<h6
										className={clsx("fontWeight400", {
											"active-skills-data":
												this.state.activeTab === "alignmentGap",
										})}
									>
										Understand the alignment gap between the leaders and
										workforce to become the data-driven organization.
									</h6>
								</div>
							</div>
						</Col>
					</Row>

					<Row>
						<Col xl={"12"} className={"trends"}>
							<h5 className={"text-center fontWeight600"}>
								More data driven insights and trends? <LoginButton />
							</h5>
						</Col>
					</Row>
				</div>
			</Fragment>
		);
	}
}

export default Organization;
