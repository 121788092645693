import React from "react";
import Highcharts from "highcharts";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import noData from "highcharts/modules/no-data-to-display";
import "./custom.scss";
import dummyData from "./dummy";
import { courseProgress } from "../../service/userService";
import { getJobSalaries } from "../../service/jobSalariesService";
noData(Highcharts);
// const dummyData = [
//   {
//     name: "Data Analyst",
//     data: [100000, 86000, 50000, 19000],
//     color: "#FCB52F",
//   },
//   {
//     name: "Data Engineer",
//     data: [80000, 30000, 40000, 12000],
//     color: "#13AD13",
//   },
//   {
//     name: "Data Scientist",
//     data: [10000, 12000, 10500, 9000],
//     color: "#105B95",
//   },
//   {
//     name: "Machine Learning Engineer",
//     data: [4000, 8000, 6000, 5000],
//     color: "#951B1E",
//   }
// ];

function calculateAvg(chartData) {
  // let data = chartData.filterData(el => el.data)
}

const seriesColors = {
  "Data Analyst": "#FCB52F",
  "Data Engineer": "#13AD13",
  "Data Scientist": "#105B95",
  "Machine Learning Engineer": "#951B1E",
};

const role_groups = [
  { id: "DA0", name: "Data Analyst" },
  { id: "DS0", name: "Data Scientist" },
  { id: "DS1", name: "Machine Learning Engineer" },
  { id: "DE0", name: "Data Engineer" },
];

export default class JobSalaries extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: dummyData,
      rolegroups: [],
      countries: [],
      levels: [],
      selectedYear: [2020],
      selectedPeriod: { id: "Y", title: "Annual Salary" },
      selectedLevels: {},
      selectedCountries: {},
    };
  }

  componentDidMount() {
    // this.renderChart(this.state.data)
    this.getSalariesBenchmark([2020], "Y");
  }

  getSalariesBenchmark = (years, SalaryFreq, role, level, country) => {
    getJobSalaries({
      year: years,
      JobRoleGroup: role,
      CountryId: country && country.id,
      MildLevel: level,
      SalaryFreq: SalaryFreq,
      selectedRoles: [],
      currency:
        (country &&
          (country.name === "Malaysia"
            ? "MYR"
            : country.name === "Singapore"
            ? "SGD"
            : "MYR")) ||
        "MYR",
    }).then((res) => {
      if (res) {

        this.setState({
          rolegroups: res.data.rolegroups,
          countries: res.data.countries,
          levels: res.data.levels.sort((a, b) =>
            a.id > b.id ? 1 : b.id > a.id ? -1 : 0
          ),
        });
        if (!this.state.selectedCountries.hasOwnProperty("id")) {
          this.handleCountryChange(res.data.countries[0]);
        }
        this.filterData(res.data.series);
      }
    });
  };

  filterData = (data) => {
    let filterdData = [];
    // let list = data.filter((el) => el.salary_freq_id === "Y");
    let list = data;
    for (let i in role_groups) {
      let l = list.filter((el) => el.rolegroup === role_groups[i].name) || [];
      l.sort((a, b) =>
        a.quarter > b.quarter ? 1 : b.quarter > a.quarter ? -1 : 0
      );

      if (l && l.length > 0) {
        let obj = {};
        let count = 0;
        obj.name = l[0].rolegroup;
        obj.color = seriesColors[l[0].rolegroup];
        let sdata = [];
        for (let j in l) {
          if (parseInt(j) + 1 !== l[count].quarter) {
            sdata.push(null);
            sdata.push({
              y: parseInt(l[count].mean),
              stDev: l[count].stdev ? parseInt(l[count].stdev_usd) : null,
              // oMin: l[count].outlying_min
              //   ? parseInt(l[count].outlying_min)
              //   : null,
              // oMax: l[count].outlying_max
              //   ? parseInt(l[count].outlying_max)
              //   : null,
              rMin: l[count].min_usd ? parseInt(l[count].min_usd) : null,
              rMax: l[count].max_usd ? parseInt(l[count].max_usd) : null,
              n1sigma: l[count].n1sigma ? parseInt(l[count].n1sigma) : null,
              p1sigma: l[count].p1sigma ? parseInt(l[count].p1sigma) : null,
              currency: l[count].currency ? l[count].currency : null,
              year: l[count].year ? l[count].year : null,
              country: this.state.selectedCountries.name,
              freq: this.state.selectedPeriod.title,
              level: this.state.selectedLevels.level
            });
          } else {
            sdata.push({
              y: parseInt(l[count].mean),
              stDev: l[count].stdev ? parseInt(l[count].stdev_usd) : null,
              // oMin: l[count].outlying_min
              //   ? parseInt(l[count].outlying_min)
              //   : null,
              // oMax: l[count].outlying_max
              //   ? parseInt(l[count].outlying_max)
              //   : null,
              rMin: l[count].min_usd ? parseInt(l[count].min_usd) : null,
              rMax: l[count].max_usd ? parseInt(l[count].max_usd) : null,
              n1sigma: l[count].n1sigma ? parseInt(l[count].n1sigma) : null,
              p1sigma: l[count].p1sigma ? parseInt(l[count].p1sigma) : null,
              currency: l[count].currency ? l[count].currency : null,
              year: l[count].year ? l[count].year : null,
              country: this.state.selectedCountries.name,
              freq: this.state.selectedPeriod.title,
              level: this.state.selectedLevels.level
            });
            count++;
          }
        }

        obj.data = sdata;
        filterdData.push(obj);
      }
    }

    this.setState(
      {
        chartData: filterdData,
      },
      () => {
        this.handleRoleChange(this.state.selectedRoles || []);
      }
    );
    // this.renderChart(filterdData);
  };

  renderChart = (chartData) => {

    const { selectedYear } = this.state;
    let categories = ["Q1", "Q2", "Q3", "Q4"];
    if (selectedYear && selectedYear.length > 1) {
      categories = ["Q1", "Q2", "Q3", "Q4", "Q1", "Q2", "Q3", "Q4"];
    }
    let plotlines = [];
    for (let i in chartData) {
      let item = chartData[i];
      let data = item.data;
      let avg = 0;
      let len = 0;
      for (let i in data) {
        if (data[i]) {
          avg += data[i].y;
          len++;
        }
      }
      avg = avg / len;
      avg = avg.toFixed(0);
      plotlines.push({
        value: avg,
        color: item.color,
        dashStyle: "solid",
        width: 2,
        label: {
          text: avg,
          style: { color: item.color },
        },
      });
    }

    Highcharts.chart("chart-div", {
      chart: {
        type: "line",
        marginTop: 40,
        events: {
          render: function () {
            let series = this.series;

            series.forEach((item) => {
              let plotLines = item.yAxis.plotLinesAndBands;

              plotLines.forEach(function (el, i) {
                el.svgElem && el.svgElem.hide();
                el.label && el.label.hide();
              }, this);
            });
          },
        },
      },
      lang: {
        noData: "No Data to display",
      },
      title: {
        text: " ",
      },

      yAxis: {
        title: {
          text: `Salary (${
            this.state.selectedCountries &&
            this.state.selectedCountries.name === "Malaysia"
              ? "MYR"
              : this.state.selectedCountries.name === "Singapore"
              ? "SGD"
              : "MYR" || "MYR"
          })`,
          align: "high",
          offset: 0,
          rotation: 0,
          y: -15,
        },
        plotLines: plotlines,
        stackLabels: {
          enabled: true,
        },
        labels: {
          format: "{value}",
        },
      },

      xAxis: {
        alignTicks: false,
        categories: categories,
      },

      legend: {
        /* layout: 'vertical', */
        /* align: 'right', */
        /* verticalAlign: 'middle' */
      },

      tooltip: {
        formatter: function (tooltip) {
          if (this.point.isNull) {
            return "Null";
          }

          // If not null, use the default formatter
          return `<span style="color: ${this.color}"><b>${
            this.series.name
          }</b></span><br/>
                        <span><b>Average:</b> ${this.point.y || "-"}</span><br/>
                        <span><b>Lower limit:</b> ${
                          this.point.n1sigma || "-"
                        }</span><br/>
                        <span><b>Upper limit:</b> ${
                          this.point.p1sigma || "-"
                        }</span><br/>`;
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
            // format: "{y}",
            // formatter: function () {
            //   console.log("formatter", this);
            //   return "<span class='chart-datalabel'><span>a</span></span>";
            // },
          },
          point: {
            events: {
              mouseOver: function () {
                var plotLines = this.series.yAxis.plotLinesAndBands;

                plotLines[this.series._i].svgElem.show();
                plotLines[this.series._i].label.show();
                let qf = document.getElementById("job-salaries-quick-facts");

                if (qf) {
                  qf.innerText = `The ${this.freq && this.freq.split(' ')[0] || ''} average job salary for ${this.level || ''} ${
                    this.series.name
                  } in ${this.year} in ${this.country} ${this.year === new Date().getFullYear() ? 'is' : 'was'} ${
                    plotLines[this.series._i].options.value
                  } ${this.currency}`;
                }
                // plotLines.forEach((el, i) => {
                //   console.log("LOADDD ::", el, i, this)
                //
                //   if (i === this.plotline - 1) {
                //     el.svgElem.show();
                //     el.label.show();
                //   } else {
                //     el.svgElem.hide();
                //     el.label.hide();
                //   }
                // });
              },
            },
          },
          events: {
            mouseOut: function () {
              var plotLines = this.yAxis.plotLinesAndBands;

              plotLines.forEach(function (el, i) {
                el.svgElem && el.svgElem.hide();
                el.label && el.label.hide();
                let qf = document.getElementById("job-salaries-quick-facts");
                if (qf) {
                  qf.innerText = ``;
                }
              }, this);
            },
          },
        },
        line: {
          marker: {
            enabled: true,
          },
        },
      },

      series: chartData,

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    });
    // this.handleRoleChange(this.state.selectedRoles || []);
  };

  handleRoleChange = (value) => {
    let newData = [];
    this.setState({
      selectedRoles: value,
    });
    const { chartData } = this.state;
    if (value.length > 0) {
      for (let i in chartData) {
        for (let j in value) {
          if (chartData[i].name === value[j].group) {
            newData.push(chartData[i]);
          }
        }
      }
    } else {
      newData = chartData;
    }
    this.renderChart(newData);
    this.setState({
      data: newData,
    });
  };

  handleCountryChange = (value) => {
    this.setState(
      {
        selectedCountries: value,
      },
      () => {
        this.reloadChart();
      }
    );
  };

  handleLevelChange = (value) => {
    this.setState(
      {
        selectedLevels: value,
      },
      () => {
        this.reloadChart();
      }
    );
  };

  handlePeriodChange = (value) => {
    this.setState(
      {
        selectedPeriod: value,
      },
      () => {
        this.reloadChart();
      }
    );
  };

  handleYearChange = (value) => {
    // let years = value ? value.map((item) => item.id) : [2020];

    this.setState(
      {
        selectedYear: value ? [value.id] : [2020],
      },
      () => {
        this.reloadChart();
      }
    );
  };

  reloadChart = () => {
    const {
      selectedYear,
      selectedPeriod,
      selectedLevels,
      selectedCountries,
    } = this.state;

    this.getSalariesBenchmark(
      selectedYear || [2020],
      (selectedPeriod && selectedPeriod.id) || "Y",
      undefined,
      (selectedLevels && selectedLevels.id) || undefined,
      (selectedCountries && selectedCountries) || undefined
    );
  };

  render() {
    const { rolegroups, countries, levels } = this.state;
    return (
      <React.Fragment>
        <div className={"chart-container"}>
          <div className={"head"}>
            <div className={"title"}>
              Salary Benchmark of Data Professionals
            </div>
            <div className={"filters"}>
              <Autocomplete
                id="combo-box-demo"
                multiple
                options={rolegroups}
                getOptionLabel={(option) => option.group}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={"mt-2"}
                    size={"small"}
                    label="Role"
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => this.handleRoleChange(value)}
              />
              <Autocomplete
                id="combo-box-demo"
                options={levels}
                getOptionLabel={(option) => option.level}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={"mt-2"}
                    size={"small"}
                    label="Level"
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => this.handleLevelChange(value)}
              />
              <Autocomplete
                id="combo-box-demo"
                options={[
                  { id: "Y", title: "Annual Salary" },
                  { id: "M", title: "Monthly Salary" },
                ]}
                style={{ width: "200px" }}
                defaultValue={{ id: "Y", title: "Annual Salary" }}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={"mt-2"}
                    size={"small"}
                    label="Period"
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => this.handlePeriodChange(value)}
              />
              {countries && (
                <Autocomplete
                  id="combo-box-demo"
                  options={countries}
                  value={this.state.selectedCountries}
                  disableClearable={true}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={"mt-2"}
                      size={"small"}
                      label="Country"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) => this.handleCountryChange(value)}
                />
              )}
              <Autocomplete
                aria-valuemax={2}
                id="combo-box-demo"
                options={[
                  { id: 2020, title: "2020" },
                  { id: 2021, title: "2021" },
                ]}
                defaultValue={{ id: 2020, title: "2020" }}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={"mt-2"}
                    size={"small"}
                    label="Year"
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => this.handleYearChange(value)}
              />
            </div>
          </div>
          <div className={"mt-3"} id={"chart-div"}></div>
        </div>
      </React.Fragment>
    );
  }
}
