import React, {Fragment} from "react";
import {Button, Col, Row} from "reactstrap";


export default class TablePage extends React.Component{

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className={"container-fluid"}>
                <Row style={{border: "2px solid black"}}>
                    <Col xl={"12"}>
                        <div style={{float:"left"}} className={"logoClass"} >
                            <img src={"/assets/img/CadsBig.png"} height={"inherit"} width={"160px"}/>
                        </div>
                        {/*<Button onClick={this.logout} className={"mr-2 mt-2"} color="secondary" style={{padding: "5px 40px",float:"right"}}>Logout</Button>*/}
                    </Col>
                </Row>
                <Row>
                    <Col xl={2}>

                    </Col>
                    <Col xl={8}>

                    </Col>
                    <Col xl={2}>

                    </Col>
                </Row>
            </div>
        )
    }

}
