import React, {Fragment} from 'react';
import {getCurrentUserInfo, getThinkificToken} from "../../service/userService";
import {toast} from "react-toastify";

export default class ThinkificRedirection extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.callThinkific();
    }

    callThinkific = () => {

        getCurrentUserInfo().then(response => {
            getThinkificToken(response.data.fname,response.data.lname, response.data.userId).then(response => {
                let token = response.data.token;
                // let school = "test-s-school-dbf6";
                let school = "thecads";
                // let url = "https://test-s-school-dbf6.thinkific.com/";
                let url = "https://learning.thecads.com/courses/take/bda101";
                // let errurl = "/err";
                let errurl = "";
                let thinkificURL = `https://${school}.thinkific.com/api/sso/v2/sso/jwt?jwt=${token}&return_to=${url}&error_url=${errurl}`

                window.location.href = thinkificURL;

                // window.open(thinkificURL)
            }).catch(error => {
                toast(error)
            })
        }).catch(error => {
            toast("Thinkific redirection error")
        })
    }
    render(){
        return(
            <Fragment>

            </Fragment>
        );
    }
}
