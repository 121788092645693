import React, {Fragment} from "react";
import {HorizontalBar} from "react-chartjs-2";

export default class OrganizationSkill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orgSkill:[]
        }
    }

    componentDidMount() {
        let orgSkill = {
            labels: ['Skill', 'Skill', 'Skill', 'Skill', 'Skill', 'Data Visualization', 'Machine Learning', 'Python'],
            datasets: [
                {
                    label: 'No. of Employees',
                    backgroundColor: 'rgba(99,133,255,0.6)',
                    borderColor: 'rgb(99,133,255,0.6)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(99,133,255,0.6)',
                    hoverBorderColor: 'rgba(99,133,255,0.6)',
                    data: [10, 20, 30, 40, 50, 60, 70, 80]
                }
            ]
        };

        let options= {
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    ticks: {
                        max: 80,
                        min: 0,
                        stepSize: 20
                    }
                }]
            },

        };

        this.setState({
            orgSkill:orgSkill,
            options:options
        })
    }

    render() {
        return(
            <div className={"hide-overflow"}>
                <HorizontalBar data={this.state.orgSkill} options={this.state.options} />
            </div>
        )
    }
}
