import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import App from './App';
import './assets/main/base.scss';
import HomePage from './views/homePage';
import InvitationRegistrationPage from './views/InvitationRegistrationPage';
import RegistrationPage from './views/RegistrationPage';
import LogOut from './views/user/logout';
import TempPage from './views/user/tempPage';
import ChartsDashBoard from './views/user/tempPage/chartPage';
import TablePage from './views/user/tempPage/tablePage';
import ThinkificRedirection from './views/user/thinkificRedirection';
import MissionControl from './views/MissionControl';
import PublicNews from './components/PublicNews/PublicNews';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import { browserName, CustomView } from 'react-device-detect';
import SignInPage from './views/SignInPage/SignInPage';
import SignUpPage from './views/SignUpPage/SignUpPage'
import JobSalaries from "./views/JobSalaries";

class Routes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isAuthenticated: false,
		};
	}

	componentDidMount() {
		if(browserName !== "Chrome" && browserName !== "Edge" && browserName !== "Firefox") {
			this.setState({
				warningModal: true
			})
		}
	}

	render() {
		const { warningModal } = this.state;
		return (
			<Fragment>
				<Route path='/post/:id' component={PublicNews} />
				<Route path='/home' component={HomePage} />
				<Route path='/app' component={App} />
				<Route path='/register' component={RegistrationPage} />
				<Route path="/signin" component={SignInPage} />
				<Route path="/signup" component={SignUpPage} />
				<Route path='/logout' component={LogOut} />
				<Route path='/thinkific-redirection' component={ThinkificRedirection} />

				<Route path='/b2bLandingPage' component={TempPage} />
				<Route path='/b2bChartPage' component={ChartsDashBoard} />
				<Route path='/missionControl' component={MissionControl} />
				<Route path='/jobsalaries' component={JobSalaries} />
				<Route
					path='/invitation'
					render={(props) => <InvitationRegistrationPage {...props} />}
				/>

				<Route
					exact
					path={'/'}
					render={(props) => <Redirect to={'/app'} {...props} />}
				/>

			</Fragment>
		);
	}
}

const mapStateToProp = (state) => ({
	roles: state.customReducer.roles,
	role: state.customReducer.role,
});

export default connect(mapStateToProp)(Routes);
