import { API_BASE_URL_LANDING } from '../../constants/urlConstants';
import { requestTalentSpy } from '../requestService';

const GET_POST_DETAILS = '/article/articledetails';

export async function fetchPostData(articleID) {
	return await requestTalentSpy({
		url: API_BASE_URL_LANDING + GET_POST_DETAILS + '?articleId=' + articleID,
		method: 'GET',
	});
}
