import React, {Fragment} from "react";
import {Row, Col, Button, Input} from "reactstrap";
import {Line} from "react-chartjs-2";

export default class AssessmentStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            learningPathCompletion:[]
        }
    }

    componentDidMount() {

        let assessmentStatus = {
            labels: ['5-20-20', '5-21-20', '5-22-20', '5-23-20'],
            datasets: [
                {
                    label: 'completed',
                    fill: false,
                    lineTension: 0.0,
                    backgroundColor: 'rgba(46,42,184,1)',
                    borderColor: 'rgba(46,42,184,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(46,42,184,1)',
                    pointBackgroundColor: 'rgba(46,42,184,1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(46,42,184,1)',
                    pointHoverBorderColor: 'rgba(46,42,184,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 5,
                    pointHitRadius: 10,
                    data: [65, 59, 80, 81]
                },
                {
                    label: 'opened',
                    fill: false,
                    lineTension: 0.0,
                    backgroundColor: 'rgba(97,88,85,1)',
                    borderColor: 'rgba(97,88,85,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(97,88,85,1)',
                    pointBackgroundColor: 'rgba(97,88,85,1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(97,88,85,1)',
                    pointHoverBorderColor: 'rgba(97,88,85,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 5,
                    pointHitRadius: 10,
                    data: [75, 69, 90, 91]
                },
                {
                    label: 'Unopened',
                    fill: false,
                    lineTension: 0.0,
                    backgroundColor: 'rgba(182,81,27,1)',
                    borderColor: 'rgba(182,81,27,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(182,81,27,1)',
                    pointBackgroundColor: 'rgba(182,81,27,1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(182,81,27,1)',
                    pointHoverBorderColor: 'rgba(182,81,27,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 5,
                    pointHitRadius: 10,
                    data: [55, 49, 70, 71]
                }

            ]
        };

        let options= {
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        callback:function(d) {
                            return d +"%";
                        },
                        max: 100,
                        min: 0,
                        stepSize: 10
                    }
                }]
            },

        };

        this.setState({
            assessmentStatus:assessmentStatus,
            options:options
        })
    }

    render() {
        return(
            <div className={"hide-overflow"}>
                <Line data={this.state.assessmentStatus} height={80} options={this.state.options} />
            </div>
        )
    }
}
