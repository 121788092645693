export default [
    {
        "quarter": 4,
        "year": 2020,
        "rolegroup": "Data Analyst",
        "salary_freq_id": "Y",
        "outlying_min": 876.49,
        "range_min": 9503.659,
        "mean": 26921.717,
        "stdev": 12782.944,
        "range_max": 53472.496,
        "outlying_max": 120762.586
    },
    {
        "quarter": 4,
        "year": 2020,
        "rolegroup": "Data Engineer",
        "salary_freq_id": "W",
        "outlying_min": 46.2925,
        "range_min": 164.72382,
        "mean": 426.55142,
        "stdev": 184.61508,
        "range_max": 832.3935,
        "outlying_max": 2323.141
    },
    {
        "quarter": 2,
        "year": 2020,
        "rolegroup": "Data Engineer",
        "salary_freq_id": "W",
        "outlying_min": 77.47,
        "range_min": 186.98296,
        "mean": 842.69434,
        "stdev": 389.10306,
        "range_max": 1768.0814,
        "outlying_max": 3107.1328
    },
    {
        "quarter": 1,
        "year": 2020,
        "rolegroup": "Data Engineer",
        "salary_freq_id": "Y",
        "outlying_min": null,
        "range_min": 9260.539,
        "mean": 16123.412,
        "stdev": 6248.1074,
        "range_max": 26453.568,
        "outlying_max": 48021.176
    },
    {
        "quarter": 4,
        "year": 2020,
        "rolegroup": "Data Scientist",
        "salary_freq_id": "Y",
        "outlying_min": null,
        "range_min": 13326.152,
        "mean": 46762.832,
        "stdev": 19601.826,
        "range_max": 86869.48,
        "outlying_max": 154106.97
    },
    {
        "quarter": 3,
        "year": 2020,
        "rolegroup": "Data Analyst",
        "salary_freq_id": "W",
        "outlying_min": null,
        "range_min": 166.12494,
        "mean": 558.00635,
        "stdev": 276.431,
        "range_max": 1203.531,
        "outlying_max": 2330.7217
    },
    {
        "quarter": 2,
        "year": 2020,
        "rolegroup": "Data Engineer",
        "salary_freq_id": "M",
        "outlying_min": 336.85,
        "range_min": 813.0377,
        "mean": 3665.0332,
        "stdev": 1692.4095,
        "range_max": 7684.672,
        "outlying_max": 15255.566
    },
    {
        "quarter": 4,
        "year": 2020,
        "rolegroup": "Data Scientist",
        "salary_freq_id": "W",
        "outlying_min": null,
        "range_min": 255.39648,
        "mean": 896.77075,
        "stdev": 376.34277,
        "range_max": 1667.8848,
        "outlying_max": 2953.4504
    },
    {
        "quarter": 3,
        "year": 2020,
        "rolegroup": "Data Scientist",
        "salary_freq_id": "M",
        "outlying_min": null,
        "range_min": 1256.1357,
        "mean": 3538.7197,
        "stdev": 1569.6141,
        "range_max": 6711.686,
        "outlying_max": 11619.612
    },
    {
        "quarter": 2,
        "year": 2020,
        "rolegroup": "Data Scientist",
        "salary_freq_id": "M",
        "outlying_min": null,
        "range_min": 2641.5198,
        "mean": 5652.779,
        "stdev": 1977.6548,
        "range_max": 9872.861,
        "outlying_max": 20457.79
    },
    {
        "quarter": 3,
        "year": 2020,
        "rolegroup": "Data Engineer",
        "salary_freq_id": "M",
        "outlying_min": null,
        "range_min": 583.403,
        "mean": 2202.8152,
        "stdev": 1025.9585,
        "range_max": 4660.5356,
        "outlying_max": 14350.724
    },
    {
        "quarter": 4,
        "year": 2020,
        "rolegroup": "Machine Learning Engineer",
        "salary_freq_id": "W",
        "outlying_min": 196.3925,
        "range_min": 317.76776,
        "mean": 810.76794,
        "stdev": 302.3512,
        "range_max": 1432.3784,
        "outlying_max": 2489.5461
    },
    {
        "quarter": 3,
        "year": 2020,
        "rolegroup": "Data Engineer",
        "salary_freq_id": "W",
        "outlying_min": null,
        "range_min": 134.17197,
        "mean": 507.28198,
        "stdev": 236.76065,
        "range_max": 1081.5212,
        "outlying_max": 3300.371
    },
    {
        "quarter": 3,
        "year": 2020,
        "rolegroup": "Data Analyst",
        "salary_freq_id": "M",
        "outlying_min": null,
        "range_min": 722.349,
        "mean": 2426.3296,
        "stdev": 1201.9795,
        "range_max": 5233.215,
        "outlying_max": 10134.478
    },
    {
        "quarter": 2,
        "year": 2020,
        "rolegroup": "Data Engineer",
        "salary_freq_id": "Y",
        "outlying_min": 4042.22,
        "range_min": 9756.462,
        "mean": 43991.66,
        "stdev": 20321.127,
        "range_max": 92312.6,
        "outlying_max": 183066.78
    },
    {
        "quarter": 3,
        "year": 2020,
        "rolegroup": "Data Analyst",
        "salary_freq_id": "Y",
        "outlying_min": null,
        "range_min": 8668.182,
        "mean": 29115.951,
        "stdev": 14423.755,
        "range_max": 62798.574,
        "outlying_max": 121613.74
    },
    {
        "quarter": 2,
        "year": 2020,
        "rolegroup": "Data Analyst",
        "salary_freq_id": "W",
        "outlying_min": null,
        "range_min": 274.14313,
        "mean": 769.32556,
        "stdev": 278.82257,
        "range_max": 1388.67,
        "outlying_max": 2731.1345
    },
    {
        "quarter": 2,
        "year": 2020,
        "rolegroup": "Machine Learning Engineer",
        "salary_freq_id": "Y",
        "outlying_min": null,
        "range_min": 24061.025,
        "mean": 70140.12,
        "stdev": 26591.08,
        "range_max": 122099.59,
        "outlying_max": 251734.72
    },
    {
        "quarter": 3,
        "year": 2020,
        "rolegroup": "Machine Learning Engineer",
        "salary_freq_id": "Y",
        "outlying_min": null,
        "range_min": 14115.995,
        "mean": 41766.746,
        "stdev": 18405.2,
        "range_max": 76156.34,
        "outlying_max": 175760.1
    },
    {
        "quarter": 3,
        "year": 2020,
        "rolegroup": "Machine Learning Engineer",
        "salary_freq_id": "W",
        "outlying_min": null,
        "range_min": 270.532,
        "mean": 800.45734,
        "stdev": 352.73383,
        "range_max": 1459.5319,
        "outlying_max": 3368.4363
    },
    {
        "quarter": 4,
        "year": 2020,
        "rolegroup": "Data Engineer",
        "salary_freq_id": "M",
        "outlying_min": 201.2675,
        "range_min": 716.24414,
        "mean": 1854.2739,
        "stdev": 802.1327,
        "range_max": 3608.824,
        "outlying_max": 10101.506
    },
    {
        "quarter": 1,
        "year": 2020,
        "rolegroup": "Data Engineer",
        "salary_freq_id": "W",
        "outlying_min": null,
        "range_min": 177.4773,
        "mean": 309.00378,
        "stdev": 119.74432,
        "range_max": 506.98297,
        "outlying_max": 920.32666
    },
    {
        "quarter": 3,
        "year": 2020,
        "rolegroup": "Machine Learning Engineer",
        "salary_freq_id": "M",
        "outlying_min": null,
        "range_min": 1176.3344,
        "mean": 3480.5623,
        "stdev": 1533.7672,
        "range_max": 6346.3613,
        "outlying_max": 14646.673
    },
    {
        "quarter": 1,
        "year": 2020,
        "rolegroup": "Data Engineer",
        "salary_freq_id": "M",
        "outlying_min": null,
        "range_min": 771.7135,
        "mean": 1343.6173,
        "stdev": 520.6751,
        "range_max": 2204.463,
        "outlying_max": 4001.7666
    },
    {
        "quarter": 4,
        "year": 2020,
        "rolegroup": "Machine Learning Engineer",
        "salary_freq_id": "M",
        "outlying_min": 878.665,
        "range_min": 1364.9243,
        "mean": 3519.7476,
        "stdev": 1320.3317,
        "range_max": 6228.2856,
        "outlying_max": 10825.081
    },
    {
        "quarter": 3,
        "year": 2020,
        "rolegroup": "Data Scientist",
        "salary_freq_id": "Y",
        "outlying_min": null,
        "range_min": 15073.628,
        "mean": 42464.633,
        "stdev": 18835.37,
        "range_max": 80540.234,
        "outlying_max": 139435.33
    },
    {
        "quarter": 4,
        "year": 2020,
        "rolegroup": "Data Engineer",
        "salary_freq_id": "Y",
        "outlying_min": 2415.26,
        "range_min": 8594.942,
        "mean": 22251.287,
        "stdev": 9625.584,
        "range_max": 43305.887,
        "outlying_max": 121218.07
    },
    {
        "quarter": 2,
        "year": 2020,
        "rolegroup": "Machine Learning Engineer",
        "salary_freq_id": "W",
        "outlying_min": null,
        "range_min": 461.12683,
        "mean": 1344.232,
        "stdev": 509.61716,
        "range_max": 2340.0315,
        "outlying_max": 4824.481
    },
    {
        "quarter": 4,
        "year": 2020,
        "rolegroup": "Data Analyst",
        "salary_freq_id": "M",
        "outlying_min": 73.04,
        "range_min": 791.97,
        "mean": 2243.4768,
        "stdev": 1065.2455,
        "range_max": 4456.0415,
        "outlying_max": 10063.55
    },
    {
        "quarter": 2,
        "year": 2020,
        "rolegroup": "Data Analyst",
        "salary_freq_id": "Y",
        "outlying_min": null,
        "range_min": 14304.419,
        "mean": 40142.26,
        "stdev": 14548.501,
        "range_max": 72458.805,
        "outlying_max": 142506.67
    },
    {
        "quarter": 3,
        "year": 2020,
        "rolegroup": "Data Scientist",
        "salary_freq_id": "W",
        "outlying_min": null,
        "range_min": 288.88626,
        "mean": 814.525,
        "stdev": 362.09125,
        "range_max": 1555.2775,
        "outlying_max": 2672.271
    },
    {
        "quarter": 2,
        "year": 2020,
        "rolegroup": "Data Scientist",
        "salary_freq_id": "Y",
        "outlying_min": null,
        "range_min": 31698.242,
        "mean": 67576.805,
        "stdev": 23425.74,
        "range_max": 117677.805,
        "outlying_max": 245493.45
    },
    {
        "quarter": 4,
        "year": 2020,
        "rolegroup": "Data Analyst",
        "salary_freq_id": "W",
        "outlying_min": 16.8,
        "range_min": 182.42827,
        "mean": 516.04034,
        "stdev": 244.89655,
        "range_max": 1024.7988,
        "outlying_max": 2314.4102
    },
    {
        "quarter": 2,
        "year": 2020,
        "rolegroup": "Machine Learning Engineer",
        "salary_freq_id": "M",
        "outlying_min": null,
        "range_min": 2005.086,
        "mean": 5845.0103,
        "stdev": 2215.9236,
        "range_max": 10174.966,
        "outlying_max": 20977.895
    },
    {
        "quarter": 4,
        "year": 2020,
        "rolegroup": "Machine Learning Engineer",
        "salary_freq_id": "Y",
        "outlying_min": 10543.975,
        "range_min": 16379.1045,
        "mean": 42206.008,
        "stdev": 15803.981,
        "range_max": 74555.09,
        "outlying_max": 129900.945
    },
    {
        "quarter": 4,
        "year": 2020,
        "rolegroup": "Data Scientist",
        "salary_freq_id": "M",
        "outlying_min": null,
        "range_min": 1110.5126,
        "mean": 3899.3572,
        "stdev": 1636.415,
        "range_max": 7252.3237,
        "outlying_max": 12842.249
    },
    {
        "quarter": 2,
        "year": 2020,
        "rolegroup": "Data Scientist",
        "salary_freq_id": "W",
        "outlying_min": null,
        "range_min": 607.4939,
        "mean": 1300.024,
        "stdev": 454.82047,
        "range_max": 2270.5552,
        "outlying_max": 4704.8706
    },
    {
        "quarter": 3,
        "year": 2020,
        "rolegroup": "Data Engineer",
        "salary_freq_id": "Y",
        "outlying_min": null,
        "range_min": 7000.8525,
        "mean": 26454.922,
        "stdev": 12335.939,
        "range_max": 56311.395,
        "outlying_max": 163039.86
    },
    {
        "quarter": 2,
        "year": 2020,
        "rolegroup": "Data Analyst",
        "salary_freq_id": "M",
        "outlying_min": null,
        "range_min": 1192.036,
        "mean": 3345.017,
        "stdev": 1212.176,
        "range_max": 6032.0576,
        "outlying_max": 11875.554
    }
]
