import React, { Fragment } from "react";
import BlockUi from "react-block-ui";
import Loader from "react-loader-spinner";
import { Col, Row } from "reactstrap";
import VideoCapture from "video-capture";
import { getTrendsData } from "../../service/landingService";
import LoginButton from "../LoginButton";
import PublicNews from "../PublicNews/PublicNews";
import { DataInsightsSubComponent } from "./dataInsightsSubComponent";
import "./post-section-style.scss";

class DataInsights extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			article_Data: [],
			open: false,
			blocking: false,
			thumbnailURL: "",
			isModalOpen: false,
			selectedID: null
		};
	}

	componentDidMount() {
		this.getTrendingData();

		let path = window.location.pathname;
		path = path.split("/")
		if(path[2]) {
			this.setState({
				isModalOpen: true,
				selectedID: path[2]
			})
		}
	}

	getTrendingData = () => {
		this.setState({
			blocking: true,
		});
		getTrendsData(0)
			.then((response) => {
				if (response && response.data !== null) {
					this.setState({
						article_Data: response.data.articlelist,
						blocking: false,
					});
				} else {
					this.setState({
						blocking: true,
					});
				}
			})
			.catch((error) => {});
	};

	toggleModal = () => {
		this.setState({
			isModalOpen: !this.state.isModalOpen,
		});
	};

	handlePostID = (id) => {
		this.setState({
			selectedID: id
		})
	}

	async test(videoUrl) {
		let { dataURL, width, height } = await new VideoCapture(videoUrl).capture(
			"10%",
		);
		this.setState({
			thumbnailURL: dataURL,
		});
	}

	render() {
		return (
			<Fragment>
				<BlockUi
					tag="div"
					blocking={this.state.blocking}
					loader={
						<Loader
							type="Oval"
							color="grey"
							height={50}
							width={50}
							timeout={13000} //13 secs
						/>
					}
					message="Loading.."
				>
					<div className="container-fluid" style={{ marginTop: "10vh" }}>
						<div className='posts-page-container'>
							<Row className={'insights'}>
								<Col xl={12}>
									<h1 className={'posts-heading '}>Data Insights &amp; Trends</h1>
								</Col>
							</Row>
							<Row className={'insights'}>
								{this.state.article_Data &&
									this.state.article_Data
										.slice(0, this.state.article_Data.length)
										.map((data, key, array) => (
											<DataInsightsSubComponent
												articleData={data}
												keyData={key}
												key={key}
												toggleModal={this.toggleModal}
												isModalOpen={this.state.isModalOpen}
												handlePostID={this.handlePostID}
											/>
										))}
								
								{this.state.isModalOpen && (
									<div style={{position: "absolute"}}>
										<PublicNews
											isModalOpen={this.state.isModalOpen}
											atricleID={this.state.selectedID}
											toggleModal={this.toggleModal}
										/>
									</div>
								)}
							</Row>
						</div>
						<Row>
							<Col className={"text-center trends"} xl={"12"}>
								<h5
									className={"fontWeight600 text-center"}
								>
									Want to Learn More? <LoginButton />{" "}
								</h5>
							</Col>
						</Row>
					</div>
				</BlockUi>
			</Fragment>
		);
	}
}

export default DataInsights;
