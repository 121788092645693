const BarChartData = [
    {
        'date': 'Jan 1, 2020',
        'numbers': 2,
        'per': '1%'
    },
    {
        'date': 'Feb 1, 2020',
        'numbers': 20000,
        'per': '5%'
    },
    {
        'date': 'Mar 1, 2020',
        'numbers': 225000,
        'per': '32%'
    },
    {
        'date': 'Apr 1, 2020',
        'numbers': 160000,
        'per': '25%'
    },
    {
        'date': 'May 1, 2020',
        'numbers': 260000,
        'per': '38%'
    },
    {
        'date': 'Jun 1, 2020',
        'numbers': 125000,
        'per': '20%'
    },
    {
        'date': 'Jul 1, 2020',
        'numbers': 275000,
        'per': '40%'
    }
]

export default BarChartData;