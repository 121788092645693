import {SET_ROLES, SET_SKILL_VERIFICATION_LINK, SET_USER, SET_KEYCLOAK_ROLE, SET_KC_INSTANCE} from "./action";


const initialState = {
    roles: [],
    user: {},
    skillVerificationLink:null,
    keycloak: null
};

export default function reducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case SET_KC_INSTANCE:
            return {
                ...state,
                keycloak: action.payload.kc
            };
        case SET_SKILL_VERIFICATION_LINK:
            return {
                ...state,
                skillVerificationLink: action.payload.link
            };
        case SET_ROLES:
            return {
                ...state,
                roles: action.payload.roles
            };
        case SET_USER:
            return {
                ...state,
                user: action.payload.user
            };
        case SET_KEYCLOAK_ROLE:
            return {
                ...state,
                role: action.payload.role
            };
        default:
            return state;
    }
}

