import React, {Fragment} from "react";
import {Row, Col, Button, Input} from "reactstrap";
import {HorizontalBar} from "react-chartjs-2";

export default class ModulePath extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modulePath:[]
        }
    }

    componentDidMount() {
        let modulePath = {
            labels: ['Data Storytelling', 'Excel for Analytics', 'R', 'Python'],
            datasets: [
                {
                    label: 'Employees',
                    backgroundColor: 'rgba(99,133,255,0.6)',
                    borderColor: 'rgb(99,133,255,0.6)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(99,133,255,0.6)',
                    hoverBorderColor: 'rgba(99,133,255,0.6)',
                    data: [400, 350, 50, 65]
                }
            ]
        };

        let options= {
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    ticks: {
                        max: 500,
                        min: 0,
                        stepSize: 100
                    }
                }]
            },

        };

        this.setState({
            modulePath:modulePath,
            options:options
        })
    }

    render() {
        return(
            <div className={"hide-overflow"}>
                <HorizontalBar data={this.state.modulePath} options={this.state.options} />
            </div>
        )
    }
}
