export const questionOptions = [
    {
        question:'Tableau automatically displays 3 main items when a trendline is used in your charts EXCEPT',
        optionA:'R-squared value ',
        optionB:'Overall trendline equation',
        optionC:'R-value',
        optionD:'P-value ',
    },
    {
        question:'Doing 5-fold cross validation, what is the size in percentage of the validation sets?',
        optionA:'5%',
        optionB:'20%',
        optionC:'50%',
        optionD:'80%',
    },
    {
        question:'For a hypothesis that has high variance (overfitting), what is the strategy to improve the model?',
        optionA:'Reduce regularization ',
        optionB:'Train longer by making the learning rate alpha smaller ',
        optionC:'Get more data (if possible) ',
        optionD:'Stop using validation set, use test set instead ',
    },
    {
        question:'Which SQL join keyword is used to retrieve all records from two tables, regardless of whether if the join condition is met?',
        optionA:'Inner Join',
        optionB:'Full Join',
        optionC:'Left Join',
        optionD:'Right Join',
    },
    {
        question:'To choose a sample of the teachers in 180 international schools in KL, we may choose 20 schools randomly and select all their teachers as sample. Which sampling method is used?',
        optionA:'Simple Random Sampling',
        optionB:'Cluster Sampling',
        optionC:'Convenience Sampling',
        optionD:'Stratified Sampling ',
    },



]
