import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
// styles
import 'react-toastify/dist/ReactToastify.min.css';
import Routes from './routes';
import { Provider } from 'react-redux';
import configureStore from "./config/configureStore";
import { hotjar } from 'react-hotjar';
import Cookies from "universal-cookie";
const cookie = new Cookies();

hotjar.initialize(2119865, 6);
const store = configureStore();
const rootElement = document.getElementById('root');
let referrer = []
if (cookie.get('referrer')) referrer = [...cookie.get('referrer')]
// referrer.push(cookie.get('referrer'))
referrer.push(document.referrer)
console.log("document.referrer ::::::::: ", referrer);
cookie.set("referrer", referrer);
const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <Component />

            </BrowserRouter>
        </Provider>,
        rootElement
    );
};

window.onerror = function (msg, url, lineNo, columnNo, error) {
    // ... handle error ...

    return null
}

renderApp(Routes);

if (module.hot) {
    module.hot.accept('./routes', () => {
        const NextApp = require('./routes').default;
        renderApp(NextApp);
    });
}
