import React, {Component, Fragment} from 'react';
import {Button, Col, Input, Row} from 'reactstrap';

import "./custom.scss";

const bcrypt = require("bcryptjs");

let windowUrl = window && window.location && window.location.href;

export default class MissionControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            compareSalt: "$2y$10$SfQdLX3.zGDR3rUgBa6S..Gsi1mjK7GnWsRlOXF5UZS7LsDuj1eVC",
            authenticated: false
        }
    }

    componentDidMount() {
        this.filterLinks()
    }

    filterLinks = () => {
        let adminLink = "https://admin.talentspy.staging.cads.ai/admin/login/";
        let customerLink = '.talentspy.staging.cads.ai/admin​/';
        let employeeLink = ".talentspy.staging.cads.ai/talentspy/users/login​/";
        let talentspyEnterpriseLink = "https://staging.cads.ai​/";
        let skillstreetsLink = "https://staging.cads.ai​/";
        let ddoAdmin = "https://ddo.thecads.com/admin/login​/";
        let ddoUser = "https://ddo.thecads.com​/";

        if(windowUrl.includes("dev")) {
            adminLink = "https://admin.talentspy.dev.skillstreets.com/admin/login/";
            customerLink = ".talentspy.dev.skillstreets.com/admin​/";
            employeeLink = ".talentspy.dev.skillstreets.com/talentspy/users/login​/";
            talentspyEnterpriseLink = "https://cadsio.dev.skillstreets.com/"
            skillstreetsLink = "https://cadsio.dev.skillstreets.com/";
            ddoAdmin = "https://ddo.thecads.com/admin/login​/";
            ddoUser = "https://ddo.thecads.com​/";
        } else if (windowUrl.includes("stag")) {
            adminLink = "https://admin.talentspy.staging.cads.ai/admin/login/";
            customerLink = ".talentspy.staging.cads.ai/admin/​";
            employeeLink = ".talentspy.staging.cads.ai/talentspy/users/login​/";
            talentspyEnterpriseLink = "https://staging.cads.ai​/"
            skillstreetsLink = "https://staging.cads.ai​/";
            ddoAdmin = "https://ddo.thecads.com/admin/login/​";
            ddoUser = "https://ddo.thecads.com​/";
        } else if (windowUrl.includes("cads.ai")) {
            adminLink = "https://admin.talentspy.cads.ai/admin/login/";
            customerLink = ".talentspy.cads.ai/admin​/";
            employeeLink = ".talentspy.cads.ai/talentspy/users/login​/";
            talentspyEnterpriseLink = "https://cads.ai​/"
            skillstreetsLink = "https://cads.ai​/";
            ddoAdmin = "https://ddo.thecads.com/admin/login​/";
            ddoUser = "https://ddo.thecads.com​/";
        }

        this.setState({
            adminLink,
            customerLink,
            employeeLink,
            talentspyEnterpriseLink,
            skillstreetsLink,
            ddoAdmin,
            ddoUser
        })
    }

    handelOnChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    authenticate = () => {
        const { compareSalt, password } = this.state;

        bcrypt.compare(password, compareSalt).then(authenticated => {
            if(authenticated) {
                this.setState({
                    authenticated
                })
            } else {
                this.setState({
                    err: true
                })
            }
        })
    }

    getCustomerLink = () => {
        const { customerLink, customerDomain } = this.state;
        const str =  `https://${customerDomain}${customerLink}`;
        return str.replace(/[^\x20-\x7E]/g, '');
    };

    render() {
        const {authenticated, err, adminLink, employeeDomain, customerLink, customerDomain, employeeLink, talentspyEnterpriseLink,
            skillstreetsLink,
            ddoAdmin,
            ddoUser} = this.state;
        console.log(adminLink)
        return(
            <Fragment>
                <div className={"mission-control container-fluid"}>
                {!authenticated ?
                    <Row>
                        <Col md={"12"}>
                            <div className={"page-protector"}>
                                <div className={"text-center"}>
                                    {err &&
                                        <p className={"mb-3 text-danger"}>Invalid Password</p>
                                    }
                                    <h5>Please Enter Password</h5>
                                    <Input
                                        className={"mt-4"}
                                        type={"password"}
                                        name={"password"}
                                        placeholder={"Password"}
                                        onChange={this.handelOnChange} />
                                    <Button className={"mt-4"} color={"primary"} onClick={this.authenticate}>
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    :
                    <div className={"pl-2 pr-2"}>
                        <Row className={"text-center"}>
                            <Col md={"3"}>
                                <div className={"logoClass p-4"} >
                                    <img src={"/assets/img/CADS_logo_beta.svg"} height={"inherit"} width={"180px"}/>
                                </div>
                            </Col>
                            <Col md={"6"} className={"text-center p-4"}>
                                <h1><b>Mission Control</b></h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={"12"}>
                                <div className={"legacy-TS-div text-center d-flex justify-content-center"}>
                                    <h3>Legacy </h3> &nbsp;&nbsp; <img alt={"Talent Spy"} className={"mt-2"} src={"https://smartskills.talentspy.dev.skillstreets.com//img/logo.png"} style={{height: "40px"}}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={"4"}>
                                <div className={"mt-4 text-center"}>
                                    <h5>CADS Admin</h5>
                                </div>
                                <div className={"mt-3 mb-3 ts-admin-url text-center"}>
                                    <a target={"_blank"} href={adminLink} >{adminLink}</a>
                                </div>
                                <div className={"pl-4 ml-3"}>
                                    Functions to use:
                                    <ul>
                                        <li>Create Customer</li>
                                        <li>Create Survey</li>
                                        <li>Publish Surveys to Customers​</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={"4"}>
                                <div className={"mt-4 text-center"}>
                                    <h5>Customer Admin</h5>
                                </div>
                                <div className={"d-flex pl-4"}>
                                    <Input size={"sm"} name={"customerDomain"} onChange={this.handelOnChange} />
                                    <Button size={"sm"} color={"secondary"} className={"ml-2"} onClick={() => {
                                        window.open(`http://${customerDomain}${customerLink}`.replace(/[^\x20-\x7E]/g, ''), "_blank")
                                    }}>
                                        GO
                                    </Button>
                                </div>
                                <div className={"pl-4 mt-2 ts-admin-url"}>
                                    {(customerDomain && customerDomain.trim().length > 0) ?
                                        <a target={"_blank"} href={`https://${customerDomain}${customerLink}`.replace(/[^\x20-\x7E]/g, '')}>https://{customerDomain}{customerLink}</a>
                                        :
                                        <>
                                            <p>{customerDomain}{customerLink}</p>

                                        </>
                                    }
                                </div>
                                <div className={"pl-4"}>
                                    Functions to use​
                                    <ul>
                                        <li>Add Departments​</li>
                                        <li>View Surveys in CADS Library​</li>
                                        <li>Add from CADS Library to My Library​</li>
                                        <li>Rename / Duplicate Assessment in My Library​</li>
                                        <li>Add Users (Administrators)​</li>
                                    </ul>
                                    <ul className={"mt-2"}>
                                        DO NOT USE​
                                        <li>Assign Assessment to Employee​</li>
                                        <li>Add Talent/Bulk Add Talent​</li>
                                        <li>These functions are managed in the NEW CADS.ai Platform in TalentSpy Enterprise</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={"4"}>
                                <div className={"mt-4 text-center"}>
                                    <h5>Employee</h5>
                                </div>
                                <div className={"d-flex pl-4"}>
                                    <Input size={"sm"} name={"employeeDomain"} onChange={this.handelOnChange} />
                                    <Button size={"sm"} color={"secondary"} className={"ml-2"} onClick={() => {
                                        window.open(`http://${employeeDomain}${employeeLink}`.replace(/[^\x20-\x7E]/g, ''), "_blank")
                                    }}>
                                        GO
                                    </Button>
                                </div>
                                <div className={"pl-4 mt-2 ts-admin-url"}>
                                    {(employeeDomain && employeeDomain.trim().length > 0) ?
                                        <a target={"_blank"} href={`http://${employeeDomain}${employeeLink}`.replace(/[^\x20-\x7E]/g, '')}>https://{employeeDomain}{employeeLink}</a>
                                        :
                                        <p>{employeeDomain}{employeeLink}</p>
                                    }
                                </div>
                                <div className={"pl-4"}>
                                    DO NOT USE​
                                    <ul className={"mt-2"}>
                                        <li>These functions are managed in the NEW CADS.ai Platform in TalentSpy Enterprise</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <Row className={"mt-3"}>
                            <Col md={"12"}>
                                <div className={"new-TS-div text-center d-flex justify-content-center"}>
                                    <h3>New CADS.ai Platform </h3>
                                </div>
                            </Col>
                        </Row>
                        <Row className={"pb-4"}>
                            <Col md={"4"}>
                                <div className={"mt-4 text-center"}>
                                    <h5>TalentSpy Enterprise​</h5>
                                </div>
                                <div className={"mt-3 mb-3 ts-admin-url text-center"}>
                                    <a type={"text/html"} target={"_blank"} href={talentspyEnterpriseLink.replace(/[^\x20-\x7E]/g, '')} >{talentspyEnterpriseLink}</a>
                                </div>
                            </Col>
                            <Col md={"4"}>
                                <div className={"mt-4 text-center"}>
                                    <h5>Skillstreet​</h5>
                                </div>
                                <div className={"mt-3 mb-3 ts-admin-url text-center"}>
                                    <a target={"_blank"} href={skillstreetsLink.replace(/[^\x20-\x7E]/g, '')} >{skillstreetsLink}</a>
                                </div>
                            </Col>
                            <Col md={"4"}>
                                <div className={"mt-4 text-center"}>
                                    <h5>Data-Driven Alignment​</h5>
                                </div>
                                <div className={"mt-3 mb-3 ts-admin-url text-center d-flex justify-content-center"}>
                                    <p>Admin: &nbsp;&nbsp;</p> {" "}<a target={"_blank"} href={ddoAdmin.replace(/[^\x20-\x7E]/g, '')} >{ddoAdmin}</a>
                                </div>
                                <div className={"mt-3 mb-3 ts-admin-url text-center d-flex justify-content-center"}>
                                    <p>User: &nbsp;&nbsp;</p> {" "}<a target={"_blank"} href={ddoUser.replace(/[^\x20-\x7E]/g, '')} >{ddoUser}</a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                }
                </div>
            </Fragment>
        )
    }

}
