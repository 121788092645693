import clsx from "clsx";
import React, { Fragment } from "react";
import Loader from "react-loader-spinner";
import { Col } from "reactstrap";
import VideoCapture from "video-capture";
import defaultImage from './gallery.png';

export class DataInsightsSubComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			article_Data: "",
			open: false,
			blocking: false,
			thumbnailURL: "",
			key: "",
		};
	}

	componentDidMount() {
		this.setState({
			article_Data: this.props.articleData,
			key: this.props.keyData,
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			article_Data: nextProps.articleData,
			key: nextProps.keyData,
		});
	}

	async test(videoUrl) {
		let { dataURL, width, height } = await new VideoCapture(videoUrl).capture(
			"10%",
		);
		this.setState({
			thumbnailURL: dataURL,
		});
	}

	handleClick = (id) => {
		this.props.toggleModal()
		this.props.handlePostID(id)
	}

	render() {
		return (
			<Fragment>
				{this.state.key < 2 && this.state.article_Data && (
					<div
						className={clsx(
							{
								'post-video-container col-xl-6 col-md-12 col-sm-12':
									this.state.article_Data.isimage === false,
							},
							{
								'post-image-container col-xl-6 col-md-12 col-sm-12':
									this.state.article_Data.isimage === true,
							},
						)}
						onClick={() => this.handleClick(this.state.article_Data.id)}
						style={{cursor: "pointer"}}
					>
						{this.state.article_Data.isimage === false &&
							this.test(this.state.article_Data.displayurl) && (
								<div className='data-insight-video'>
									<div className={'insight-common-img insight-video-image'}>
										{this.state.thumbnailURL === '' ? (
											<div className={'loader-inBetween'}>
												<span>
													<Loader
														type='Oval'
														color='grey'
														height={50}
														width={50}
														timeout={25000}
													/>
												</span>
											</div>
										) : (
											<video
												id='video'
												controls
												controlsList='nodownload'
												poster={this.state.thumbnailURL}
											>
												<source src={this.state.article_Data.displayurl} />
											</video>
										)}
									</div>
								</div>
							)}
						{this.state.article_Data.isimage === true && (
							<div
								className={clsx('data-insight-video', {
									customBackground: this.state.article_Data.displayurl === '',
								})}
							>
								<img
									className={clsx(
										'post-video insight-common-img insight-video-image customWidthImage',
										{ customWidth: this.state.article_Data.displayurl === '' },
									)}
									src={
										this.state.article_Data.displayurl !== ''
											? this.state.article_Data.displayurl
											: '/assets/img/gallery.png'
									}
									alt='no file found'
								/>
							</div>
						)}

						<div className={'below-visual'} style={{ marginTop: '10px' }}>
							<h5 className={'fontWeight600'} style={{ color: '#536578' }}>
								{this.state.article_Data.title}
							</h5>
							<h6 className={'fontWeight400'} style={{ color: '#98a3ae' }}>
								<div className='post__description'>
									{this.state.article_Data.description}
								</div>
							</h6>
						</div>
					</div>
				)}

				{this.state.key >= 2 && this.state.article_Data && (
					<div
						className="post-link"
						onClick={() => this.handleClick(this.state.article_Data.id)}
						style={{cursor: "pointer"}}
					>
						<Col xl={'1'}>
							{this.state.article_Data.isimage === true && (
								<img
									className={clsx('insight-common-img insights-image-left', {
										customWidthList: this.state.article_Data.displayurl === '',
									})}
									src={this.state.article_Data.displayurl}
									onError={(e)=>{e.target.onerror = null; e.target.src=defaultImage}}
									alt='no file found'
								/>
							)}
							{this.state.article_Data.isimage === false &&
								this.test(this.state.article_Data.displayurl) && (
									<img
										className='insight-common-img insights-image-left'
										src={this.state.thumbnailURL}
										onError={(e)=>{e.target.onerror = null; e.target.src=defaultImage}}
										alt='no file found'
									/>
								)}
						</Col>
						<Col xl={'8'} className='links-data mr-auto'>
							<h5 className={'fontWeight600'} style={{ color: '#516276' }}>
								{this.state.article_Data.title}
							</h5>
							<h6 className={'fontWeight400'} style={{ color: '#9aa5af' }}>
								<div className='post__description'>
									{this.state.article_Data.description}
								</div>
							</h6>
						</Col>
					</div>
				)}
			</Fragment>
		);
	}
}
