import React, { Fragment } from "react";
import clsx from "clsx";
import { Row, Col, Label, Input, Button } from "reactstrap";
// import "./Individual.css";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { questionOptions } from "../../data/SkillsQuestion";
import VideoCapture from "video-capture";
import { getPublicJobs } from "../../service/landingService";

const months = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];
function getDate(date) {
	return (
		new Date(date).getUTCDate() +
		" " +
		months[new Date(date).getUTCMonth()] +
		" " +
		new Date(date).getUTCFullYear()
	);
}

class Individual extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: "assessSkills",
			// activeTab: "findJobs",
			questionData: [],
			progressBar: 0,
			currentOption: 0,
			jobData: [],
		};
	}

	componentDidMount() {
		this.changeQuestion(this.state.currentOption, "next");
		let length = questionOptions.length;
		let percent = 100 / length;
		let rounded = Math.trunc(percent);
		percent = rounded;
		// let videoUrl = "https://s3.amazonaws.com/codecademy-content/courses/React/react_video-cute.mp4"
		let videoUrl = "/assets/video/video1.mp4";
		this.setState({
			progressBar: percent,
			thumbnailUrl: "",
			videoUrl: videoUrl,
		});
		this.test(videoUrl);
		this.getPublicJobsData();
	}

	async test(videoUrl) {
		let { dataURL, width, height } = await new VideoCapture(videoUrl).capture(
			"10%",
		);
		this.setState({
			thumbnailURL: dataURL,
		});
	}

	handleClick = (event) => {
		let id = event.target.id;
		this.setState({ activeTab: id });
	};

	getPublicJobsData = () => {
		getPublicJobs(0, 5).then((response) => {
			if (response && response.data) {
				this.setState({
					jobData: response.data.content,
				});
			} else {
			}
		});
	};

	changeQuestion = (currentQue, button) => {
		if (currentQue < 0) {
			currentQue = 0;
		}
		let length = questionOptions.length;
		let percent = 100 / length;
		let rounded = Math.trunc(percent);
		percent = rounded;
		let queOpt;

		let progressPercent = this.state.progressBar;
		if (currentQue < length) {
			queOpt = questionOptions[currentQue];
			this.setState({
				currentOption: currentQue,
			});
		} else {
			let len = length - 1;
			queOpt = questionOptions[len];
			this.setState({
				currentOption: len,
			});
		}
		if (button === "next" && this.state.progressBar < 100) {
			progressPercent = percent + this.state.progressBar;
		} else if (button === "prev" && this.state.progressBar > percent) {
			progressPercent = this.state.progressBar - percent;
		}
		this.setState({
			progressBar: progressPercent,
			questionData: queOpt,
		});
	};

	onSubmit = () => {};

	handleOnChange = (event) => {
		this.setState({
			[`${event.target.name}${this.state.currentOption}`]: event.target.value,
		},() => console.log(this.state));
	};

	render() {
		return (
			<Fragment>
				<div className="container-fluid">
					<Row>
						<Col xl={"12"} className={"whiteHeading"}>
							<h5
								className={"fontWeight900"}
								style={{ fontSize: "large", color: "#a6b0b9" }}
							>
								FOR INDIVIDUALS
							</h5>
							<h1
								className={"fontWeight900"}
								style={{ fontSize: "x-large", color: "#4e6074" }}
							>
								Futureproof your career
							</h1>
						</Col>
					</Row>
					<Row>
						<Col xl={"7"} className="col-assess-skills">
							{this.state.activeTab === "assessSkills" ? (
								<div className="assess-skills-div">
									<Row className="assess-skills">
										<Col xl={"12"}>
											<Row className={"left-title"}>
												<Col xl={12}>
													<h5>Assesment Questionare</h5>
												</Col>
											</Row>
										</Col>
										<Col xl={"12"} style={{ padding: "5vh" }}>
											<div className={"assess-skills-progbar"}>
												<div className={"prog-percent"}>
													<span> {this.state.progressBar} % </span>
												</div>
												<div className={"prog-percent-bar"}>
													<Progress
														percent={this.state.progressBar}
														status="default"
														theme={{
															default: {
																symbol: " ",
																color: "green",
															},
														}}
													/>
												</div>
												<div>
													<h6>
														Time Left{" "}
														<span>
															<b>12:30 Mins</b>
														</span>{" "}
													</h6>
												</div>
											</div>
											<div className={"assess-skills-content"}>
												<Label
													className={"fontWeight700"}
													style={{ margin: "0px 0px 20px 0px" }}
												>
													{this.state.questionData.question}
												</Label>
												<p>
													<Input
														value={"optionA"}
														onChange={this.handleOnChange}
														type="checkbox"
														name={"optionA"}
														checked={this.state[`optionA${this.state.currentOption}`]}
													/>
													<Label>{this.state.questionData.optionA}</Label>
												</p>
												<p>
													<Input
														value={"optionB"}
														onChange={this.handleOnChange}
														type="checkbox"
														name={"optionB"}
														checked={this.state[`optionB${this.state.currentOption}`]}
													/>
													<Label>{this.state.questionData.optionB}</Label>
												</p>
												<p>
													<Input
														value={"optionC"}
														onChange={this.handleOnChange}
														type="checkbox"
														name={"optionC"}
														checked={this.state[`optionC${this.state.currentOption}`]}
													/>
													<Label>{this.state.questionData.optionC}</Label>
												</p>
												<p>
													<Input
														value={"optionD"}
														onChange={this.handleOnChange}
														type="checkbox"
														name={"optionD"}
														checked={this.state[`optionD${this.state.currentOption}`]}
													/>
													<Label>{this.state.questionData.optionD}</Label>
												</p>
											</div>
											<div className={"row assess-skills-button"}>
												<div className={"col-md-9 btn-prev-nxt"}>
												{this.state.currentOption !== 0 && (
                            <Button
                              outline
                              onClick={() =>
                                this.changeQuestion(
                                  this.state.currentOption - 1,
                                  "prev",
                                )
                              }
                              className={"btn-previous"}
                              color="primary"
                            >
                              Previous
                            </Button>
                          )}
                          {questionOptions.length !==
                            this.state.currentOption + 1 && (
                            <Button
                              outline
                              onClick={() =>
                                this.changeQuestion(
                                  this.state.currentOption + 1,
                                  "next",
                                )
                              }
                              className={"btn-nxt"}
                              color="primary"
                            >
                              Next
                            </Button>
                          )}
												</div>
												<div className={"col-md-3 btn-sub"}>
													<Button
														onClick={this.onSubmit}
														className={"btn-submit"}
														handle
														color="primary"
													>
														Submit
													</Button>
												</div>
											</div>
										</Col>
									</Row>
								</div>
							) : (
								<></>
							)}
							{this.state.activeTab === "learnSkills" ? (
								<div className="learn-skills-div">
									<div className="learn-skills">
										<video
											id="video"
											controls
											controlsList="nodownload"
											poster={this.state.thumbnailURL}
										>
											<source src={this.state.videoUrl} />
										</video>
										{/*<img className="insight-common-img insight-image" src={"/assets/img/Video_Icon.png"} alt=""/>*/}
										{/*<h6 className="insight-video-title">Video</h6>*/}
									</div>
								</div>
							) : (
								<></>
							)}
							{this.state.activeTab === "findJobs" ? (
								<div className="assess-jobs-div-public">
									<div className={"text-center"}>
										<h1>Here are the latest jobs </h1>
									</div>
									<div className="assess-jobs">
										{this.state.jobData &&
											this.state.jobData.map((job, key) => (
												<>
													<Row className={"job-card-public "}>
														<Col
															xl={"1"}
															lg={"1"}
															md={"1"}
															className={"pl-0 pr-0 narrow-screen-icon"}
														>
															<div
																className={"pr-2 mb-2"}
																style={{ fontSize: "large", float: "right" }}
															>
																<FontAwesomeIcon icon={faBookmark} />
															</div>
														</Col>
														<Col xl={"2"} lg={"2"} md={"2"}>
															<div className="job-card-grey-image">
																{job.organizationDetails.profilePhoto ? (
																	<img
																		className="insight-common-img insights-image-left"
																		src={job.organizationDetails.profilePhoto}
																		alt=""
																	/>
																) : (
																	<img
																		className="insight-common-img insights-image-left"
																		src={"/assets/img/gallery.png"}
																		alt=""
																	/>
																)}
																{/*<img className="insight-common-img insights-image-job" src={"/assets/img/gallery.png"} alt="" />*/}
																{/*<img className="insight-common-img insights-image-job" src={job.organizationDetails.profilePhoto} alt="" />*/}
															</div>
														</Col>
														<Col xl={"7"} lg={"7"} md={"7"}>
															<div className={"job-card-info"}>
																<p className={"fontWeight700"}>
																	{job.designation}
																</p>
																<p className={"mb-2 fontWeight700"}>
																	{job.location}, {job.country.name}
																</p>
																<p className={"job-card-lightFont "}>
																	Posted on {getDate(job.publishedDate)}
																</p>
																<p className={"job-card-lightFont"}>
																	{job.jobAppliedCount} Applications
																</p>
															</div>
														</Col>
														<Col
															xl={"1"}
															lg={"1"}
															md={"1"}
															className={"pl-0 pr-0 wide-screen-icon"}
														>
															<div style={{ float: "right" }}>
																<FontAwesomeIcon icon={faBookmark} />
															</div>
														</Col>
														<Col xl={"2"} lg={"2"} md={"2"}>
															<div className="job-card-grey-image text-center pt-4">
																<h3 className={"fontWeight700"}>N/A</h3>
																<p
																	className={"fontWeight600 pt-4"}
																	style={{
																		fontSize: "11px",
																		color: "#8080806e",
																	}}
																>
																	4 out of 6 skills required
																</p>
															</div>
														</Col>
													</Row>
													<hr className={"hr-0"} />
												</>
											))}
									</div>
								</div>
							) : (
								<></>
							)}
						</Col>
						<Col xl={"4"} className={"common-left-menu"}>
							<div style={{ padding: "9vh 9vh 0vh 9vh" }}>
								<div >
									<div className="bulb-image">
									<img
										src={"/assets/img/bulb.png"}
										height={"80px"}
										width={"50px"}
										alt=""
									/>
									</div>
								</div>
							</div>
							<div style={{ padding: "0vh 7vh 9vh 5vh" }}>
								<div style={{ marginTop: "5vh" }}>
									<h3
										className={clsx("fontWeight700 pointerCursor", {
											"active-skills": this.state.activeTab === "assessSkills",
										})}
										id={"assessSkills"}
										onClick={this.handleClick}
									>
										Assess Skills
									</h3>
									<h6
										className={clsx("fontWeight400", {
											"active-skills-data":
												this.state.activeTab === "assessSkills",
										})}
									>
										Assess your data analytics and AI skills.
									</h6>
								</div>
								<div style={{ marginTop: "5vh" }}>
									<h3
										className={clsx("fontWeight700 pointerCursor", {
											"active-skills": this.state.activeTab === "learnSkills",
										})}
										id={"learnSkills"}
										onClick={this.handleClick}
									>
										Learn Skills
									</h3>
									<h6
										className={clsx("fontWeight400", {
											"active-skills-data":
												this.state.activeTab === "learnSkills",
										})}
									>
										Learn data, analytics and AI skills.
									</h6>
								</div>
								<div style={{ marginTop: "5vh" }}>
									<h3
										className={clsx("fontWeight700 pointerCursor", {
											"active-skills": this.state.activeTab === "findJobs",
										})}
										id={"findJobs"}
										onClick={this.handleClick}
									>
										Find Jobs
									</h3>
									<h6
										className={clsx("fontWeight400", {
											"active-skills-data":
												this.state.activeTab === "findJobs",
										})}
									>
										Find relevant data jobs based on your skills.
									</h6>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</Fragment>
		);
	}
}

export default Individual;
