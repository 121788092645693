import { request, requestTalentSpy } from "./requestService";
import {API_BASE_URL_LANDING} from "../constants/urlConstants"
const GET_JOB_SALARIES_DATA = "/salarybenchmark";

export function getJobSalaries(data) {
    return request({
        url: API_BASE_URL_LANDING + GET_JOB_SALARIES_DATA,
        method: "POST",
        body: data
    })
}
