import {request, requestTalentSpy} from "../requestService";
import {API_BASE_TALENTSPY_URL, API_BASE_URL_SKILLSTREETS} from "../../constants/urlConstants";
const SUGGEST_COUNTRY = '/public/suggestCountry';
const GET_ORG_DEPARTMENTS = "/getDepartmentList.json";
const VALIDATE_INVITATION_TOKEN = "/validateRegisterToken.json"
const REGISTER_EMP = "/registerEmployee.json"
const REGISTER_EMP_B2C = "/registerEmployeeWithPassword.json"

export async function searchCountry(query) {
    return await request({
        url: API_BASE_URL_SKILLSTREETS + SUGGEST_COUNTRY + '/' + query,
        method: 'GET'
    })
}

export async function getDeparmentList(orgEmail) {
    return await requestTalentSpy({
        url: API_BASE_TALENTSPY_URL + GET_ORG_DEPARTMENTS,
        method: 'POST',
        body: JSON.stringify({
            email: orgEmail
        })
    })
}
export async function validateInvitationToken(tenant_id, token) {
    return await requestTalentSpy({
        url: API_BASE_TALENTSPY_URL + VALIDATE_INVITATION_TOKEN,
        method: 'POST',
        body: JSON.stringify({
            tenant_id: tenant_id,
            token: token
        })
    })
}
export async function registerB2bIndividual(endpoint, data) {
    return await requestTalentSpy({
        url: endpoint + REGISTER_EMP,
        method: 'POST',
        body: JSON.stringify(data)
    })
}
export async function registerB2CIndividual(data) {
    return await requestTalentSpy({
        url: API_BASE_TALENTSPY_URL + REGISTER_EMP_B2C,
        method: 'POST',
        body: JSON.stringify(data)
    })
}

