import { KEYCLOAK_URL } from "./urlConstants";

export const AUTHENTICATION = "authentication";

export const INIT_OPTIONS = {
    url: KEYCLOAK_URL + '/',
    realm: 'cads',
    clientId: 'b2c-client'
}

export const INIT_OPTIONS_LOGIN = {
    url: KEYCLOAK_URL + '/',
    realm: 'cads',
    clientId: 'b2c-client',
    onLoad: 'login-required'
}
