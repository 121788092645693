import React, {Fragment, Suspense} from 'react';
import NavBar from "../components/NavBar";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "../components/Home";
import QuickFacts from "../components/QuickFacts";
import DataInsights from "../components/DataInsights";
import Individual from "../components/Individual";
import Organization from "../components/Organization";
import Footer from "../components/Footer";
import Cookie from 'universal-cookie';
import {BASE_COOKIE} from "../constants/urlConstants";
import {browserName} from "react-device-detect";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
const cookie = new Cookie();

export default class HomePage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ready: false
        }
    }

    componentDidMount() {
        cookie.remove('access_token', {path: '/', httpOnly: false, domain: BASE_COOKIE})
        cookie.remove('access_token', {httpOnly: false, domain: BASE_COOKIE})
        cookie.remove('access_token', {domain: BASE_COOKIE})
        cookie.remove('access_token')
        setTimeout(() => {
            this.setState({
                ready: true
            })
        }, 300)

        if (browserName !== "Chrome" && browserName !== "Edge" && browserName !== "Firefox") {
            this.setState({
                warningModal: true,
            });
        }
    }

    onRedirectCallbackAuth0 = (appState) => {
        window.history.replaceState(
            {},
            document.title,
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    };

    render() {
const { warningModal } = this.state;
        return(
this.state.ready &&
            <Fragment>
                <NavBar />
                <Home/>
                <div className="viewDesktop" style={{margin:"0px", padding:"0px"}}>
                    <Individual/>
                    <Organization/>
                    <QuickFacts/>
                    <DataInsights/>
                </div>
                <Footer/>
                {browserName !== "Chrome" && browserName !== "Edge" && browserName !== "Firefox" &&
                <Modal isOpen={warningModal}>
                    <ModalHeader toggle={() => {this.setState({warningModal: false})}}>
                        Note
                    </ModalHeader>
                    <ModalBody>
                        For a better and smoother user experience, we highly recommend using Chrome or Edge web browsers.
                    </ModalBody>
                </Modal>
                }
            </Fragment>
        )
    }

}
