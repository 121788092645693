import React, {Fragment} from "react";
import {Button} from "reactstrap";
import {IS_PROD, REDIRECTION_URL} from "../../constants/urlConstants";
import {Link} from "react-router-dom";
import * as Keycloak from "../NavBar";
import {INIT_OPTIONS} from "../../constants/global";

export default class LoginButton extends React.Component {
    constructor(props) {
        super(props)
    }
    registerUser = () => {

        window.location.href="/signup";
    }
    render() {
        const loginTO = "/signin";
        return (
            <Fragment>
                <Button  onClick={this.registerUser}  color="primary" style={{padding: "2px 40px"}}>Sign up</Button>
                &nbsp;
                {/*{IS_PROD ? <Button disabled={IS_PROD} outline*/}
                {/*                   style={{padding: "2px 40px", border: "2px solid grey", fontWeight: "bold"}}*/}
                {/*                   color="primary">Login</Button> :*/}
                    <Link className={"footerLink"} to={loginTO}><Button outline style={{
                        padding: "2px 40px",
                        border: "2px solid grey",
                        fontWeight: "bold"
                    }} color="primary">Login</Button></Link>
                {/*}*/}
            </Fragment>
        )}
}
