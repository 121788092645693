import React, {Component, Fragment} from "react";
import {Col, Row} from 'reactstrap';
import "./style.css"
import RegFields from "./RegFields";
export default class InvitationRegistrationPage extends Component {
    constructor(props){
        super(props);

        this.state = {

        }
    }

    render() {
        return(
            <Fragment>
                <div className={"container-fluid pl-0"} style={{width: '100%'}}>
                    <Row>
                        <Col md={"6"}>
                            <div className={"d-flex align-items-center justify-content-center"} style={{height: '100vh',background:'#21CFBA'}}>
                                {/*<span className={"ml-auto"}>*/}
                                    <img src={require("./acceltic.png")} height={"inherit"} width={"220x"}/>
                                {/*</span>*/}
                            </div>
                        </Col>
                        <Col md={"6"}>
                            <RegFields {...this.props}/>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        )
    }
}
